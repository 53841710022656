import {Component, OnInit, ViewChild} from '@angular/core';
import {Bill, BillForm, BillService, PageQuery, Proposal, ProposalService, SearchItem} from "@tatynanny/api";
import {Router} from "@angular/router";
import * as moment from 'moment';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.page.html',
  styleUrls: ['./invoice.page.scss'],
})
export class InvoicePage implements OnInit {

  segment: 'SENT' | 'ACTIVE' | 'ALL' = 'ALL';
  data: Bill[] = [];
  displayedColumns: string[] = ["startDate", "timeScheduleMonth.parentContract.contractNanny.pricing.productType", "timeScheduleMonth.candidate.account.lastname", "hoursCompleted", "totalPriceTTC", "billStatus", "action"];
  service: string = "";
  inputId: string = "";
  selectedDate: string = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageQuery: PageQuery = {
    fields: [
      {field: "billId", value: this.inputId},
      {field: "timeScheduleMonth.parentContract.contractNanny.pricing.productType", value: this.service},
      {field: "billStatus", value: this.segment},
      {field: "startDate", value: this.selectedDate}
    ],
    sort: 'startDate',
    direction: 'desc',
  }

  searchItems: SearchItem[] = [];

  constructor(
    private billService: BillService,
    private router: Router,
  ) { }

  ngOnInit(): void {

  }

  refresh(): void {
    this.searchItems = [
      {field: "billId", value: this.inputId},
      {field: "timeScheduleMonth.parentContract.contractNanny.pricing.productType", value: this.service},
      {field: "billStatus", value: this.segment},
      {field: "startDate", value: this.selectedDate}
    ];
    this.pageQuery.fields = this.searchItems;
    this.billService.searchBill(this.pageQuery).subscribe((res: any) => {
      console.log('[invoice] (search)', res);
      this.data = res.content;
    })
  }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.pageQuery.sort = this.sort.active;
      this.pageQuery.direction = this.sort.direction;
      console.log(this.sort.active);
    });
    this.pageQuery.page = this.paginator.pageIndex;
    this.pageQuery.pageSize = this.paginator.pageSize;

    merge(this.sort.sortChange, this.paginator.page).pipe(startWith({}), switchMap(() => {
      return this.billService.searchBill(this.pageQuery);
    })).subscribe((data: any) => {
      console.log('[invoice] (search)', data);
      (this.data = data.content)
    });
  }

  onSegmentChange(event: any): void {
    console.log(event);
    this.segment = event;
    this.refresh();

  }

  onInputChange(event: any): void {
    console.log('[invoice] (onInputChange)', event);
    this.inputId = event;
    this.refresh();
  }

  onServiceChange(event: any): void {
    console.log('[invoice] (onServiceChange)', event);
    this.service = event.value;
    this.refresh();
  }

  onDateChange(event: any): void {
    console.log('[invoice] (onDateChange)', event);
    this.selectedDate = event;
    this.refresh();
  }

  openInvoice(bill: Bill): void {
    console.log('[invoice] (openInvoice)', bill);
    if (bill.userType == 'SALARIED') {
      this.router.navigateByUrl(`/mon-espace/factures/invoice-detail/${bill.id}`);
    } else {
      console.log('AUTO ENTREPRONNEUR');
      this.router.navigateByUrl(`/mon-espace/factures/invoice-detail-ae/${bill.id}`);
    }


  }


}
