import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recrutement',
  templateUrl: './recrutement.component.html',
  styleUrls: ['./recrutement.component.scss'],
})
export class RecrutementComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
