import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import {EnvService} from 'src/app/services/environment/env.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {NavigationService} from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'app-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.scss'],
})
export class MenuNavigationComponent implements OnInit {

  public prestationsPages = [
    { title: 'Nos prestations', url: '/home/ludique', icon: 'clipboard-outline'},
    { title: 'Recrutement', url: '/home/recrutement', icon: 'people-circle-outline' },
    { title: 'Accompagnement', url: '/home/accompagnement', icon: 'people-outline' },
    { title: 'Tarifs', url: '/home/tarifs', icon: 'pricetag-outline' },
    { title: 'Notre Histoire', url: '/home/histoire', icon: 'book-outline' },
  ];


  isLoggedIn: boolean = false;

  constructor(
    private menu: MenuController,
    private keycloakIonicService: KeycloakIonicService,
    public navigationService: NavigationService,
    private envService: EnvService,
  ) {
  }

  ngOnInit() {
    this.navigationService.isLoggedIn = this.keycloakIonicService.getKeycloakInstance().authenticated;
    this.isLoggedIn = this.navigationService.isLoggedIn;

  }


  login(): void {
    this.keycloakIonicService.login();
  }

  logout(): void {
    this.keycloakIonicService.logout();
  }

  async closeMenu(): Promise<void> {
    await this.menu.close('menu');
  }

  redirectToNanny(): void {
    window.location.href = `${this.envService.frontBabysitterUrl}`;
  }
}
