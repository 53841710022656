import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accompagnement',
  templateUrl: './accompagnement.component.html',
  styleUrls: ['./accompagnement.component.scss'],
})
export class AccompagnementComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
