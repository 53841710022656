import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RequestForEstimate, RfeService} from '@tatynanny/api';

@Component({
  selector: 'app-estimate-detail',
  templateUrl: './estimate-detail.component.html',
  styleUrls: ['./estimate-detail.component.scss'],
})
export class EstimateDetailComponent implements OnInit {

  estimateId: any;
  estimate: any;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private rfeService: RfeService,
  ) { }

  ngOnInit() {
    this.estimateId = this.activatedRoute.snapshot.paramMap.get('id');

    this.rfeService.getRfe(this.estimateId).subscribe(res => {
      console.log('[estimate-detail] (getRfe)', res);
      if(res.result) {
        this.rfeService.getEstimateRfe(res.result).subscribe(res => {
          this.estimate = res.result;
        })
      }
    })

  }

}
