import {Injectable} from '@angular/core';
import {LoaderProvider} from 'src/app/loader-provider/loader.provider';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  loader: any;

  constructor(
    public loaderProvider: LoaderProvider,
  ) { }

  success(message: string): void {
    Swal.fire({
      icon: 'success',
      title: message,
      heightAuto: false,
      timer: 2000,
      confirmButtonColor: 'var(--ion-color-tn-blue)',
    });
  }

  error(error: string): void {
    Swal.fire({
      icon: 'error',
      heightAuto: false,
      title: 'Erreur',
      html: error,
      confirmButtonColor: 'var(--ion-color-danger)',
    });
  }

  temp(message: string, time?: number, cssClass?: string): void {
    Swal.fire({
      icon: 'info',
      title: 'Information',
      html: message,
      timer: time || 3000,
      timerProgressBar: true,
      heightAuto: false,
      customClass: cssClass,
    });
  }

  tempAction(message: string, time?: number, action?: () => any, cssClass?: string): void {
    Swal.fire({
      position: 'top-end',
      html: message,
      timer: time || 3000,
      timerProgressBar: true,
      heightAuto: false,
      customClass: cssClass,
      icon: 'info',
      showConfirmButton: true,
      showCloseButton: false,
      showDenyButton: false,
      showLoaderOnConfirm: true,
      confirmButtonColor: 'var(--ion-color-tn-blue)',
    }).then(result => {
      if (result.isConfirmed) {
        action();
      }
    })
  }

  confirm(message: string, btnName?: string, action?: () => any) {
    Swal.fire({
      title: 'Information',
      html: message,
      heightAuto: false,
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      showDenyButton: false,
      confirmButtonText: btnName || 'OK',
      confirmButtonColor: 'var(--ion-color-tn-blue)',
    }).then(result => {
      if (result.isConfirmed) {
        action().then(() => {
          this.closeLoader();
          Swal.fire(
            'Confimer',
            'Les modifications ont été sauvegardées',
            'success'
          );
        })
      }
    });
  }


  async loading(): Promise<void> {
    var pp = new Promise<void>((res, rej) => {
      this.loaderProvider.showLoader();
      res();
    });
    return pp;
  }

  async closeLoader() {
    this.loaderProvider.hideLoader();
  }


  ask(title: string, message: string, btnName?: string, action?: () => void, btnCancelName?: string, actionCancel?: () => void) {
    Swal.fire({
      heightAuto: false,
      icon: 'question',
      title: title,
      html: message,
      showLoaderOnConfirm: true,
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: btnName || 'OK',
      confirmButtonColor: 'var(--ion-color-tn-blue)',
      denyButtonText: btnCancelName || 'Annuler',
      denyButtonColor: 'var(--ion-color-danger)',
      // cancelButtonText: btnCancelName || 'Annuler',
      // cancelButtonColor: 'var(--ion-color-tn-orange)',
      // showCancelButton: false,
    }).then(res => {
      if (res.isConfirmed) {
        action();
      } else if (res.isDenied) {
        actionCancel();
      }
    })
  }



}
