import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {BillService, ContactUsForm, FrontgeneralService, FrontNotificationResourceService, FrontparentService, Notification, NotificationService, Parent, Proposal, ProposalService, ProposalStatus} from '@tatynanny/api';
import * as moment from 'moment';
import {AlertService} from 'src/app/services/alert/alert.service';
import {EnvService} from 'src/app/services/environment/env.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';


export interface PendingInfo {
  status?: any,
  statut?: any,
  id?: number,
  text?: string,
  link?: string,
  icon?: string,
  color?: string,
}



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {

  loaded: boolean = false;
  percentProfile: number = 0;
  percentNeed: number = 0;
  percentInfo: number = 0;
  percentDoc: number = 0;
  form: ContactUsForm = {
    subject: '',
    email: '',
    message: '',
  };
  tabError: string[] = ['', ''];
  errorUserForm: boolean = false;
  option: string = "";
  notifications: Notification[] = [];
  currentDate: any;
  sliceValue: number = 10;
  nbNotif: number = 0;
  parent: Parent;

  pendingProposal: boolean = false;
  pendingRfe: boolean = false;
  pendingContract: boolean = false;
  pendingTimesheet: boolean = false;
  pendingBill: boolean = false;

  proposalChecked: boolean = false;
  profileChecked: boolean = false;

  pendingInfos: PendingInfo[] = [];


  constructor(
    private parentService: FrontparentService,
    private frontgeneral: FrontgeneralService,
    private alertService: AlertService,
    private notificationService: FrontNotificationResourceService,
    private router: Router,
    private invoiceService: BillService,
    private envService: EnvService,
    private platform: Platform,
    private proposalService: ProposalService,
    private kcService: KeycloakIonicService
  ) { }

  checkProgress(parent: Parent): void {

    if (parent.civilite != null) this.percentProfile = this.percentProfile + 12.5;
    if (parent.birthdate != null) this.percentProfile = this.percentProfile + 12.5;
    if (parent.account.lastname != null) this.percentProfile = this.percentProfile + 12.5;
    if (parent.account.firstname != null) this.percentProfile = this.percentProfile + 12.5;
    if (parent.account.phoneNumber != null) this.percentProfile = this.percentProfile + 12.5;
    if (parent.account.email != null) this.percentProfile = this.percentProfile + 12.5;
    if (parent.child_list.length > 0 || parent.nbEnfants > 0) this.percentProfile = this.percentProfile + 12.5;
    if (parent.formattedAddress != null) this.percentProfile = this.percentProfile + 12.5;

    if (parent.aboutMe != null) this.percentInfo = this.percentInfo + 50;
    if (parent.files.avatar) this.percentInfo = this.percentInfo + 50;

    if (parent.files.address) this.percentDoc = this.percentDoc + 33.3;
    if (parent.files.idRecto) this.percentDoc = this.percentDoc + 33.3;
    if (parent.files.idVerso) this.percentDoc = this.percentDoc + 33.4;

    this.parentService.loadParentNeeds().forEach(r => {
      if (r.items.length > 0) {
        this.percentNeed = 100;
      } else {
        this.percentNeed = 0;
      }
    });
  }


  loadData(event): void {
    console.log(event);

    setTimeout(() => {
      console.log('Done');
      event.target.complete();
      this.sliceValue = this.sliceValue + 5;
      if (this.sliceValue >= this.notifications.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  deleteNotif(notification: Notification): void {
    this.alertService.ask('Attention', 'Voulez-vous supprimer définitivement la notification ?', 'Oui, supprimer', () => {
      this.notificationService.deleteNotification(notification.id).subscribe(res => {
        console.log(res);
        this.refresh();
      })
    }, 'Annuler', () => {

    })
  }

  ionViewWillEnter() {
    this.proposalChecked = localStorage.getItem('proposalChecked') != null ? true : false;
    this.profileChecked = localStorage.getItem('profileChecked') != null ? true : false;
    this.refresh();
  }

  refresh(): void {
    this.nbNotif = 0;
    this.percentProfile = 0;
    this.percentNeed = 0;
    this.percentInfo = 0;
    this.percentDoc = 0;
    this.pendingInfos = [];
    this.notifications = [];
    this.currentDate = moment();

    this.kcService.isLoggedIn().then(connected => {
      if (connected) {

        this.parentService.getPendingInfo().subscribe((res: any) => {
          if (res.result) {

            Object.entries(res.result).forEach((info: any) => {
              let pi: PendingInfo = {};
              let object: any = info[0];
              let statut: any = Object.keys(info[1])[0];
              let id: any = Object.values(info[1])[0];
              console.log(id);

              if (object == 'RequestForEstimate' && statut == 'false') {
                pi.id = id;
                pi.text = "Demande de devis en cours"
                pi.link = `/mon-espace/demandes/${id}`;
                pi.status = statut;
                pi.statut = "En cours";
              } else if (object == 'Proposal' && statut == 'SENT') {
                pi.id = id;
                pi.text = "Un devis est disponible"
                pi.link = `/mon-espace/devis/detail/${id}`;
                pi.status = statut;
                pi.statut = "En cours";
              } else if (object == 'SubContract' && statut == 'SENT') {
                pi.id = id;
                pi.text = "Un contrat est disponible"
                pi.link = `/mon-espace/contrats/`;
                pi.status = statut;
                pi.statut = "En cours";
              } else if (object == 'TimeScheduleMonth') {
                pi.id = id;
                pi.link = `/mon-espace/feuille-de-temps/event/${id}`;
                pi.status = statut;
                pi.icon = 'calendar'
                if (statut == 'SENT') {
                  pi.text = "Un nouveau relevé d'heures est disponible";
                  pi.statut = 'Nouveau';
                } else if (statut == 'PENDING') {
                  pi.text = "Un relevé d'heures est en cours";
                  pi.statut = 'En cours';
                }
              } else if(object == 'Bill') {
                pi.id = info[1];
                pi.link = `/mon-espace/factures`;
                pi.status = statut;
                if (statut == 'SENT') {
                  pi.text = "Une nouvelle facture est disponible";
                  pi.statut = 'Nouveau';
                } else if (statut == 'WAITING') {
                  pi.text = "Une facture est en attente de paiement";
                  pi.statut = 'En attente';
                } else if(statut == 'NOT_PAYED') {
                  pi.text = "Une facture n'est pas encore payée";
                  pi.statut = 'Non payée';
                }
              }

              this.pendingInfos.unshift(pi);
            })
            console.log(res.result);
          }
        });

        this.parentService.apiV1ParentProfileLoadProfileGet().subscribe(r => {
          console.log(r);
          this.parent = r.result;
          this.form.email = r.result.account.email;
          this.checkProgress(r.result);

          this.loaded = true;
        })

        if (this.platform.platforms().includes('desktop')) {
          this.notificationService.getNotifications().subscribe(res => {
            console.log(res);
            res.items.sort(function (a, b) {
              if (a.creationDate >= b.creationDate) {
                return -1;
              } else {
                return 1;
              }
            });
            for (let item of res.items) {
              if (item.read == false) {
                this.nbNotif = this.nbNotif + 1;
              }
              if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'weeks') > 0) {
                item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'weeks').toString() + 'sem';
              } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'days') > 0) {
                item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'days').toString() + 'j';
              } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'hours') > 0) {
                item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'hours').toString() + 'h';
              } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'minutes') > 0) {
                item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'minutes').toString() + 'min';
              } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'seconds') > 0) {
                item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'seconds').toString() + 's';
              }
              this.notifications.push(item);
            }
          })
        }
      }
    });
  }

  ngOnInit() {
  }

  navigation(notification: Notification): void {
    this.notificationService.readNotification(notification.id).subscribe(res => {
      console.log(res);
      this.refresh();
    })

    if (notification.type == 'proposal') {
      this.router.navigateByUrl(`/mon-espace/devis/detail/${notification.itemId}`);
    } else if (notification.type == 'contract') {
      this.parentService.loadSubContractById(notification.itemId).forEach(r => {
        console.log('CONTRACT LOADED :', r);
        if (r.parentContract.contractNanny.pricing.productType === 'EXTRACURRICULAR') {
          if (!r.candidate.autoEntreprise) {
            this.router.navigateByUrl(`/mon-espace/contrats/detail-contrat/${notification.itemId}/salarie`);
          } else {
            this.router.navigateByUrl(`/mon-espace/contrats/detail-contrat-ae/${notification.itemId}`);
          }
        }
        if (r.parentContract.contractNanny.pricing.productType === 'MAID') {
          this.alertService.confirm('Le contrat est en cours de réalisation par l\'équipe TatyNanny');
        }
        if (r.parentContract.contractNanny.pricing.productType === 'EDUCATIONAL_SUPPORT') {
          this.alertService.confirm('Le contrat est en cours de réalisation par l\'équipe TatyNanny');
        }
      });
    } else if (notification.type == 'bill') {

      this.invoiceService.loadBillById(notification.itemId).forEach(r => {
        // if (r.result.timeScheduleMonth.parentContract.contractNanny.pricing.productType === 'EXTRACURRICULAR') {
        if (r.result.userType == 'SALARIED') {
          this.router.navigateByUrl(`/mon-espace/factures/invoice-detail/${notification.itemId}`);
        } else {
          console.log('AUTO ENTREPRONNEUR');
          this.router.navigateByUrl(`/mon-espace/factures/invoice-detail-ae/${notification.itemId}`);
        }
        // }
        // if (r.result.timeScheduleMonth.parentContract.contractNanny.pricing.productType === 'MAID') {
        //   this.alertService.confirm('Contrat', 'La facture est en cours de réalisation par l\'équipe TatyNanny', '', () => { });
        // }
        // if (r.result.timeScheduleMonth.parentContract.contractNanny.pricing.productType === 'EDUCATIONAL_SUPPORT') {
        //   this.alertService.confirm('Contrat', 'La facture est en cours de réalisation par l\'équipe TatyNanny', '', () => { });
        // }
      });
    } else if (notification.type == 'timesheet') {
      this.router.navigateByUrl(`/mon-espace/feuille-de-temps/event/${notification.itemId}`);
    }
  }

  onSelect(event): void {
    console.log(event);
    this.form.subject = event.detail.value;
  }

  checkcontactForm() {
    this.errorUserForm = false;
    if (this.form.email.length == 0) {
      this.tabError[0] = 'Veuillez renseigner votre sujet';
      this.errorUserForm = true;
    }
    if (this.form.subject.length == 0) {
      this.tabError[1] = 'Veuillez renseigner un message';
      this.errorUserForm = true;
    }
    return this.errorUserForm;
  }

  send(): void {
    if (!this.checkcontactForm()) {
      this.alertService.ask('Information', 'Confirmer votre envoi ?', 'Envoyer', () => {
        this.frontgeneral.apiV1GeneralContactPost(this.form).forEach(r => {
          if (r.status) {
            this.option = '';
            this.form.subject = '';
            this.form.email = '';
            this.form.message = '';
            this.alertService.success('Votre message à bien été envoyé. Nous vous répondrons dans les plus brefs délais.');
          }
        }).catch(e => {
          console.log(e);
          this.alertService.error(`Erreur, le message n'a pas pu être envoyé. Veuillez réessayez ultérieurement.`);
        })
      });
    }
  }

  navigateToProposal(proposalId: number): void {
    localStorage.setItem('proposalChecked', JSON.stringify(true));
    this.router.navigateByUrl(`/mon-espace/devis/detail/${proposalId}`)
  }

  navigateToProfil(): void {
    localStorage.setItem('profileChecked', JSON.stringify(true));
    this.router.navigateByUrl('/mon-espace/profil');
  }

}
