import {HttpEventType, HttpErrorResponse, HttpHeaders, HttpClient} from '@angular/common/http';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Account, DownloadService, FrontparentService, Parent} from '@tatynanny/api';
import {of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Camera, CameraResultType} from '@capacitor/camera';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AlertService} from 'src/app/services/alert/alert.service';
import {EnvService} from 'src/app/services/environment/env.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {UploadService} from 'src/app/services/upload/upload.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.page.html',
  styleUrls: ['./info.page.scss'],
})
export class InfoPage implements OnInit {


  modify: boolean = false;

  aboutMe: string;
  /**
   * Parent account
   */
  parentAccount: Parent = new class implements Parent {
    aboutMe: string;
    account: Account;
    activite: string;
    address: string;
    addressNumber: string;
    age: string;
    birthdate: string;
    city: string;
    civilite: number;
    country: string;
    distance: number;
    enfants: string;
    files: {[p: string]: string};
    fonction: string;
    formattedAddress: string;
    id: number;
    latitude: number;
    longitude: number;
    nationalite: string;
    salary: number;
    zipcode: string;
  }

  imgURL: string | ArrayBuffer | any;
  @ViewChild("inputPicture", {static: false}) fileUpload: ElementRef;
  files = [];

  loaded: boolean = false;

  constructor(
    private parentService: FrontparentService,
    private alert: AlertService,
    private uploadService: UploadService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private envService: EnvService,
    private keycloakService: KeycloakIonicService,
    private downloadService: DownloadService
  ) { }


  fileSelect(): void {
    const fileUpload = this.fileUpload.nativeElement;
    for (let index = 0; index < fileUpload.files.length; index++) {
      const file = fileUpload.files[index];
      this.files.push({data: file, inProgress: false, progress: 0});
    }
  }

  uploadFile(file) {
    console.log(file);

    this.uploadService.upload('CLIENT', 'avatar', file.data).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;

        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
        }
      });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.imgURL = event.target.result;
      }
    }
  }

  ngOnInit() {
    this.alert.loading();
    this.keycloakService.isLoggedIn().then(connected => {
      if (connected) {
        this.parentService.apiV1ParentProfileLoadProfileGet().forEach(r => {
          console.log(r);
          this.aboutMe = r.result.aboutMe
          this.imgURL = this.envService.apiUrl + `/api/v1/files/${r.result.account.id}/avatar/download`;
          console.log('IMG URL : ', this.imgURL);
          this.loaded = true;
          this.alert.closeLoader();
        }).catch(e => {
          console.log(e);
          this.alert.closeLoader();
          // this.alert.errorApi();
        })
      } else {
        this.alert.closeLoader();
        this.alert.error('Vous êtes déconnecté.');
        this.router.navigateByUrl('/home/tatynanny', {
          replaceUrl: true,
        })
      }
    }).catch(e => {
      console.log(e);
      this.alert.closeLoader();
    })
  }


  updateAboutMe(): void {
    this.parentAccount.aboutMe = this.aboutMe
    // this.parentService.apiV1ParentProfileUploadFileFileTypePost()
    this.parentService.saveAboutMe(this.parentAccount).forEach(r => {
      if (this.files.length > 0) {
        this.uploadFile(this.files[0]);
      }
      this.alert.success('Descritpion enregistrée')
      this.router.navigateByUrl('/mon-espace/documents');
    }).catch(e => {
      console.log(e);
      // this.alert.errorApi()
    })

  }

}
