import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-datetime',
  templateUrl: './modal-datetime.component.html',
  styleUrls: ['./modal-datetime.component.scss'],
})
export class ModalDatetimeComponent implements OnInit {

  @Input('time') time: any;
  @Input('day') day: any;
  @Input('at') at: any;
  @Input('index') index: any;
  // @Input('min') min: any;

  dateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ";

  init: boolean = false;

  title: string = '';

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    // if(this.min != null) {
    //   this.min = moment(this.min).format(this.dateFormat);
    //   console.log('MOMENT MIN', this.min);
    // }

    if(this.at == 'start') {
      this.title = 'Heure de début';
    } else {
      this.title = 'Heure de fin';
    }

    console.log('TITRE: ', this.title);
    this.time = moment().locale('fr').hours(0).minutes(0).seconds(0).format(this.dateFormat);
    this.init = true;
  }


  onChange(ev: any): void {
    //console.log(ev);
    this.time = ev.detail.value;
  }

  dismissModal(): void {
    let object = {
      time: this.time,
      day: this.day,
      at: this.at,
      index: this.index,
    }
    this.modalController.dismiss(object);
  }

  dismissWithNothing(): void {
    this.modalController.dismiss();
  }

}
