import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractStatus'
})
export class ContractStatusPipe implements PipeTransform {

  transform(value: string): string {
    if(value == 'NEW') {
      value = "Nouveau";
    } else if(value == 'PENDING') {
      value = "En attente";
    } else if(value == 'SENT') {
      value = "En cours";
    } else if(value == 'ACTIVE') {
      value = 'Validé';
    } else if(value == 'EXPIRED') {
      value = "Expiré";
    } else if(value == 'SUSPENDED') {
      value = 'Suspendu';
    }
    return value;
  }

}
