import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '@tatynanny/api';

@Pipe({
  name: 'productTypePipe'
})
export class ProductTypePipePipe implements PipeTransform {

  transform(value: ProductType | String): any {
    if(value == 'EXTRACURRICULAR') {
      value = 'Garde périscolaire';
    } else if(value == 'BABYSITTING') {
      value = 'Garde occasionnelle';
    } else if(value == 'EDUCATIONAL_SUPPORT') {
      value = 'Soutien scolaire';
    } else if(value == 'MAID') {
      value = 'Soin du domicile';
    }
    return value;
  }

}
