import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-time',
  templateUrl: './modal-time.component.html',
  styleUrls: ['./modal-time.component.scss'],
})
export class ModalTimeComponent implements OnInit {

  init: boolean = false;
  dateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ";
  time: any;
  @Input('title') title: string;
  @Input('selectTime') selectTime: any;
  @Input('event') event: any;
  
  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    console.log(this.event);
    if(this.selectTime == undefined) {
      this.time = moment().locale('fr').hours(0).minutes(0).seconds(0).format(this.dateFormat);
    } else {
      console.log("SELECT TIME : ", this.selectTime);
    }
  }

  ionViewDidEnter() {
    this.init = true;
  }


  close(): void {
    this.modalCtrl.dismiss();
  }

  dismissModal(): void {
    if(this.selectTime == undefined) {
      this.modalCtrl.dismiss({
        time: this.time,
        title: this.title
      });
    } else {
      this.modalCtrl.dismiss({
        selectTime: this.selectTime,
      });
    }
  }

  dismissModalEvent(): void {
    this.modalCtrl.dismiss({
      selectTime: this.selectTime,
      event: this.event,
    })
  }

  onChangeTime(ev: any): void {
    //console.log(ev);
    this.time = ev.detail.value;
  }

  onChange(ev: any): void {
    console.log(ev);
    this.selectTime = ev.detail.value;
  }

}
