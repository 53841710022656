import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frequency'
})
export class FrequencyPipe implements PipeTransform {

  transform(value: string): string {
    if(value == 'WEEKLY') {
      value = "Par semaine"
    } else if(value == 'EVERY_TWO_WEEKS') {
      value = "Une semaine sur deux";
    } else if(value == "ONE_WEEK_PER_MONTH") {
      value = "Une semaine par mois"
    }

    return value;
  }

}
