import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   component: BoardPage,
  //   children: [
  //     {
  //       path: 'tableau-de-bord',
  //       loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  //     },
  //     {
  //       path: 'profile',
  //       loadChildren: () => import('./profilePages/profile/profile.module').then( m => m.ProfilePageModule)
  //     },
  //     {
  //       path: 'info',
  //       loadChildren: () => import('./profilePages/info/info.module').then( m => m.InfoPageModule)
  //     },
  //     {
  //       path: 'docs',
  //       loadChildren: () => import('./profilePages/docs/docs.module').then( m => m.DocsPageModule)
  //     },
  //     {
  //       path: 'planning',
  //       loadChildren: () => import('./planningPages/planning/planning.module').then( m => m.PlanningPageModule)
  //     },
  //     {
  //       path: 'extracurricular',
  //       loadChildren: () => import('./planningPages/extracurricular/extracurricular.module').then( m => m.ExtracurricularPageModule)
  //     },
  //     {
  //       path: 'occasional',
  //       loadChildren: () => import('./planningPages/occasional/occasional.module').then( m => m.OccasionalPageModule)
  //     },
  //     {
  //       path: 'contract',
  //       loadChildren: () => import('./contract/contract.module').then( m => m.ContractPageModule)
  //     },
  //     {
  //       path: 'timesheet',
  //       loadChildren: () => import('./timesheet/timesheet.module').then( m => m.TimesheetPageModule)
  //     },
  //     {
  //       path: 'invoice',
  //       loadChildren: () => import('./invoice/invoice.module').then( m => m.InvoicePageModule)
  //     },
  //     {
  //       path: 'attestation',
  //       loadChildren: () => import('./attestation/attestation.module').then( m => m.AttestationPageModule)
  //     },
  //     {
  //       path: 'payment',
  //       loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  //     },
  //     {
  //       path: 'rfe',
  //       loadChildren: () => import('./profilePages/rfe/rfe.module').then( m => m.RfePageModule)
  //     },
  //     {
  //       path: 'devis',
  //       loadChildren: () => import('./devis/devis.module').then( m => m.DevisPageModule)
  //     },
  //     {
  //       path: 'mes-demandes',
  //       loadChildren: () => import('./profilesPages/estimate/estimate.module').then( m => m.EstimatePageModule)
  //     },
  //   ]
  // },
  // {
  //   path: '',
  //   redirectTo: 'board/dashboard',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BoardPageRoutingModule {}
