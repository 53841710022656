import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ludique',
  templateUrl: './ludique.component.html',
  styleUrls: ['./ludique.component.scss'],
})
export class LudiqueComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
