import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {MenuNavigationComponent} from './components/menu-navigation/menu-navigation.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AgmCoreModule} from '@agm/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {AccountType, Configuration} from '@tatynanny/api';
import {ModalPaymentCardComponent} from './components/modal-payment-card/modal-payment-card.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EnvService} from './services/environment/env.service';
import {EnvServiceProvider} from './services/environment/env.service.provider';
import {KeycloakIonicService} from './services/keycloak/keycloak-ionic.service';
import {initializer} from './services/keycloak/keycloak.initializer';
import {KeycloakInterceptorService} from './services/keycloak/keycloak.interceptor.service';
import {APP_CONFIG} from './config/app.config';
import {StarterComponent} from './components/starter/starter.component';
import {AboutusAppComponent} from './components/aboutus-app/aboutus-app.component';
import {SwiperModule} from 'swiper/angular';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {HomePageRoutingModule} from './home/home-routing.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgCalendarModule} from 'ionic2-calendar';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {BoardPageRoutingModule} from './connected/board/board-routing.module';
import {ClientNavigationComponent} from './components/client-navigation/client-navigation.component';
import {ModalCalendarEventComponent} from './components/modal-calendar-event/modal-calendar-event.component';
import {ModalDatetimeComponent} from './components/modal-datetime/modal-datetime.component';
import {ModalDayofweekComponent} from './components/modal-dayofweek/modal-dayofweek.component';
import {ModalRfeComponent} from './components/modal-rfe/modal-rfe.component';
import {ModalTimeComponent} from './components/modal-time/modal-time.component';
import {BoardPage} from './connected/board/board.page';
import {ContractPage} from './connected/board/contract/contract.page';
import {DetailContratAePage} from './connected/board/contract/detail-contrat-ae/detail-contrat-ae.page';
import {DetailContratMaidPage} from './connected/board/contract/detail-contrat-maid/detail-contrat-maid.page';
import {DetailContratPage} from './connected/board/contract/detail-contrat/detail-contrat.page';
import {DashboardPage} from './connected/board/dashboard/dashboard.page';
import {DetailPage} from './connected/board/devis/detail/detail.page';
import {DevisPage} from './connected/board/devis/devis.page';
import {InvoiceDetailAePage} from './connected/board/invoice/invoice-detail-ae/invoice-detail-ae.page';
import {InvoiceDetailPage} from './connected/board/invoice/invoice-detail/invoice-detail.page';
import {InvoicePage} from './connected/board/invoice/invoice.page';
import {RfePage} from './connected/board/profilePages/rfe/rfe.page';
import {EventPage} from './connected/board/timesheet/event/event.page';
import {TimesheetPage} from './connected/board/timesheet/timesheet.page';
import {CivilitePipe} from './pipes/civilite.pipe';
import {DatePipePipe} from './pipes/date-pipe.pipe';
import {DayOfWeekPipe} from './pipes/day-of-week.pipe';
import {FrequencyPipe} from './pipes/frequency.pipe';
import {HourPipe} from './pipes/hour.pipe';
import {MonthPipe} from './pipes/month.pipe';
import {ProductTypePipePipe} from './pipes/product-type-pipe.pipe';
import {HistoireComponent} from './components/about-pages/histoire/histoire.component';
import {PartenairesComponent} from './components/about-pages/partenaires/partenaires.component';
import {ValeursComponent} from './components/about-pages/valeurs/valeurs.component';
import {ContactComponent} from './components/contact/contact.component';
import {FaqComponent} from './components/faq/faq.component';
import {FooterComponent} from './components/footer/footer.component';
import {LegacyComponent} from './components/legacy/legacy.component';
import {NewsletterComponent} from './components/newsletter/newsletter.component';
import {LudiqueComponent} from './components/prestations-pages/ludique/ludique.component';
import {OccasionnelComponent} from './components/prestations-pages/occasionnel/occasionnel.component';
import {PeriscolaireComponent} from './components/prestations-pages/periscolaire/periscolaire.component';
import {SoutienComponent} from './components/prestations-pages/soutien/soutien.component';
import {RegisterComponent} from './components/register/register.component';
import {AccompagnementComponent} from './components/tatynanny-pages/accompagnement/accompagnement.component';
import {PrestationsComponent} from './components/tatynanny-pages/prestations/prestations.component';
import {RecrutementComponent} from './components/tatynanny-pages/recrutement/recrutement.component';
import {TarifsComponent} from './components/tatynanny-pages/tarifs/tarifs.component';
import {TermsComponent} from './components/terms/terms.component';
import {AskProposalInfoComponent} from './home/ask-proposal/ask-proposal-info/ask-proposal-info.component';
import {AskProposalNeedsComponent} from './home/ask-proposal/ask-proposal-needs/ask-proposal-needs.component';
import {AskProposalServiceComponent} from './home/ask-proposal/ask-proposal-service/ask-proposal-service.component';
import {AskProposalComponent} from './home/ask-proposal/ask-proposal.component';
import {HomePage} from './home/home.page';
import {TatynannyComponent} from './home/tatynanny/tatynanny.component';
import {MinAgeIntervalPipe} from './pipes/min-age-interval.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {EstimateComponent} from './connected/board/profilePages/estimate/estimate.component';
import {AddCardComponent} from './components/add-card/add-card.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {CustomPaginatorConfig} from './config/custom-paginator';
import {EstimateDetailComponent} from './connected/board/profilePages/estimate/estimate-detail/estimate-detail.component';
import {CreateEstimateComponent} from './connected/board/profilePages/estimate/create-estimate/create-estimate.component';
import {ProposalStatusPipe} from './pipes/proposal-status.pipe';
import {ContractStatusPipe} from './pipes/contract-status.pipe';
import {BillStatusPipe} from './pipes/bill-status.pipe';
import {TimeScheduleStatusPipe} from './pipes/time-schedule-status.pipe';
import {InfoPage} from './connected/board/profilePages/info/info.page';
import {DetailContratEduPage} from './connected/board/contract/detail-contrat-edu/detail-contrat-edu.page';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        CreateEstimateComponent,
        EstimateDetailComponent,
        AddCardComponent,
        AppComponent,
        MenuNavigationComponent,
        ModalPaymentCardComponent,
        StarterComponent,
        AboutusAppComponent,

        // HOME
        MinAgeIntervalPipe,
        AskProposalInfoComponent,
        AskProposalComponent,
        AskProposalServiceComponent,
        AskProposalNeedsComponent,
        TatynannyComponent,
        HistoireComponent,
        PartenairesComponent,
        ValeursComponent,
        LudiqueComponent,
        OccasionnelComponent,
        PeriscolaireComponent,
        SoutienComponent,
        AccompagnementComponent,
        PrestationsComponent,
        RecrutementComponent,
        TarifsComponent,
        RegisterComponent,
        FaqComponent,
        ContactComponent,
        LegacyComponent,
        FooterComponent,
        TermsComponent,
        NewsletterComponent,
        HomePage,

        // BOARD
        InfoPage,
        ClientNavigationComponent,
        DashboardPage,
        BoardPage,
        DevisPage,
        DetailPage,
        RfePage,
        ContractPage,
        DetailContratPage,
        TimesheetPage,
        EventPage,
        DetailContratAePage,
        InvoicePage,
        InvoiceDetailPage,
        InvoiceDetailAePage,
        DetailContratMaidPage,
        DetailContratEduPage,
        
        ProductTypePipePipe,
        CivilitePipe,
        FrequencyPipe,
        DayOfWeekPipe,
        DatePipePipe,
        HourPipe,
        MonthPipe,

        ModalDatetimeComponent,
        ModalRfeComponent,
        ModalDayofweekComponent,
        ModalTimeComponent,
        ModalCalendarEventComponent,

        EstimateComponent,
        ProposalStatusPipe,
        ContractStatusPipe,
        BillStatusPipe,
        TimeScheduleStatusPipe,


    ],
    imports: [
        MatSortModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule,
        CommonModule,
        HomePageRoutingModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatSelectModule,


        // BOARD MODULE 
        ReactiveFormsModule,
        BoardPageRoutingModule,
        MatCheckboxModule,
        NgCalendarModule,
        Ng2SearchPipeModule,


        SwiperModule,
        MatGoogleMapsAutocompleteModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAAr8z3OjnqnW5Wqp-r4eBWizbu6eayxk8',
            libraries: ['places'],
        }),
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
    ],
    exports: [],
    providers: [
        {provide: MatPaginatorIntl, useClass: CustomPaginatorConfig},
        EnvServiceProvider,
        {provide: LOCALE_ID, useValue: 'fr'},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide: Configuration,
            useFactory: (envService: EnvService) => {
                console.log('api: ', envService.apiUrl);
                const configBase = {
                    // set configuration parameters here.
                    withCredentials: true,
                    basePath: envService.apiUrl,
                };
                return new Configuration(configBase);
            },
            deps: [EnvService],
            multi: false,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakInterceptorService,
            multi: true,
        },
        {
            provide: KeycloakIonicService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [KeycloakIonicService, EnvService],
            multi: true,
        },
        {
            provide: APP_CONFIG,
            useValue: {
                userType: AccountType.Client
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
