import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BillService, Pricing} from "@tatynanny/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.page.html',
  styleUrls: ['./invoice-detail.page.scss'],
})
export class InvoiceDetailPage implements OnInit {

  detailId: string;
  title: string;
  currentFacture: any;
  currentFactId: any;
  currentTimeSched: any;
  babySitter: any;
  includes: boolean = false;
  tva: number = 0;
  priceHT: any;
  totalTVA: any;
  totalHT: any;
  totalHours: any;
  minutes: any;
  thTTC = 0;
  @ViewChild('invoiceDiv') invoiceDiv;
  @ViewChild('invoicePage1') invoicePage1;
  taux: number = 0;
  totalTTC: number = 0;

  service: Pricing;
  designation: string = "";

  tvaMultiple: number = 1.1;
  parentLastname: string = '';
  parentFirstname: string = '';
  parentAddress: string = '';
  hoursCompleted: any;
  tauxTva: string = "10%";

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private invoiceService: BillService,

  ) { }

  ngOnInit() {
    this.detailId = this.activatedRoute.snapshot.paramMap.get('id');
    this.invoiceService.loadBillById(Number(this.detailId)).forEach(r => {
      console.log('BILL LOADED :', r.result);
      this.currentFacture = r.result;
      this.currentFactId = r.result.id;
      this.currentTimeSched = r.result.timeScheduleMonth;

      this.parentLastname = this.currentFacture.timeScheduleMonth.parent.account.lastname;
      this.parentFirstname = this.currentFacture.timeScheduleMonth.parent.account.firstname;
      this.parentAddress = this.currentFacture.timeScheduleMonth.parent.formattedAddress;
      this.hoursCompleted = this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted;



      this.service = r.result.timeScheduleMonth.parentContract.contractNanny.pricing;
      if(this.service.productType == 'EXTRACURRICULAR') {
        this.designation = "Garde d'enfants à domicile";
      } else if(this.service.productType == 'MAID') {
        if(this.service.pricingType == 'PROFESSIONAL') {
          this.designation = "Entretien de ménage";
        } else {
          this.designation = "Entretien de ménage à domicile";
        }
      } else if(this.service.productType == 'EDUCATIONAL_SUPPORT') {
        this.designation = "Soutien scolaire à domicile";
      } else if(this.service.productType == 'BABYSITTING') {
        this.designation = "Garde occasionnelle";
      }
      
      if(r.result.professional == null) {
        this.tva = 0.1;
        this.currentFacture.professional = false;
      } else if(r.result.professional == true) {
        this.tva = 0.2;
        this.currentFacture.professional = true;
      } else if(r.result.professional == false) {
        this.tva = 0.1;
        this.currentFacture.professional = false;
      }


      
      console.log(this.tva);

      if(this.currentFacture.timeScheduleMonth.subContract.increaseChildren == true) {
        this.taux = this.taux + (this.currentFacture.timeScheduleMonth.subContract.percentChild != null ? this.currentFacture.timeScheduleMonth.subContract.percentChild : 0);
      }
      if(this.currentFacture.timeScheduleMonth.subContract.increaseLang == true) {
        this.taux = this.taux + (this.currentFacture.timeScheduleMonth.subContract.percentLang != null ? this.currentFacture.timeScheduleMonth.subContract.percentLang : 0);
      }

      if(this.taux != null && this.taux != 0) {
        this.totalTTC = (this.currentFacture.totalPricePerHour * ((this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted)) + ((this.currentFacture.totalPricePerHour * (this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted)) * this.taux) / 100);
      } else {
        this.totalTTC = (this.currentFacture.totalPricePerHour * ((this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted)));
      }
      
      this.totalHours = r.result.timeScheduleMonth.hoursCompleted;
      /** Calculate prices
       * */
      this.thTTC = this.currentFacture.timeScheduleMonth.calculatedPrice / this.totalHours;
      this.priceHT = this.thTTC / 1.1;
      this.totalHT = this.priceHT * r.result.timeScheduleMonth.hoursCompleted;



      if(this.isFloat(this.totalHours)){
        this.minutes = ((this.totalHours % 1) * 60).toFixed(0);
        this.totalHours = this.totalHours - (this.totalHours % 1);
      }


      this.title = r.result.timeScheduleMonth.parentContract.proposal.requestForEstimateLink.pricing.productType;
      this.babySitter = r.result.timeScheduleMonth.candidate;
    });
  }

  isFloat(n){
    return Number(n) === n && n % 1 !== 0;
  }

  async generatePdf(){
    const imgWidth = 210;
    const pageHeight = 295;
    const position = 0;
    let imgHeight = 0;
    const doc = new jsPDF('p', 'mm');
    const image1 = await this.getImageData(this.invoiceDiv.nativeElement);
    imgHeight = image1.height * imgWidth / image1.width;
    doc.addImage(image1.url, 'PNG', 0, position, imgWidth, imgHeight);
    doc.save('Facture-'+this.currentFacture.billId+'.pdf');
  }

  async getImageData(element){
    const canvas = await html2canvas(element);
    return {url: canvas.toDataURL("image/jpeg"), height: canvas.height, width: canvas.width}
  }
}
