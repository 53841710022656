import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HistoireComponent} from '../components/about-pages/histoire/histoire.component';
import {PartenairesComponent} from '../components/about-pages/partenaires/partenaires.component';
import {ValeursComponent} from '../components/about-pages/valeurs/valeurs.component';
import {ContactComponent} from '../components/contact/contact.component';
import {FaqComponent} from '../components/faq/faq.component';
import {LegacyComponent} from '../components/legacy/legacy.component';
import {LudiqueComponent} from '../components/prestations-pages/ludique/ludique.component';
import {OccasionnelComponent} from '../components/prestations-pages/occasionnel/occasionnel.component';
import {PeriscolaireComponent} from '../components/prestations-pages/periscolaire/periscolaire.component';
import {SoutienComponent} from '../components/prestations-pages/soutien/soutien.component';
import {RegisterComponent} from '../components/register/register.component';
import {StarterComponent} from '../components/starter/starter.component';
import {AccompagnementComponent} from '../components/tatynanny-pages/accompagnement/accompagnement.component';
import {PrestationsComponent} from '../components/tatynanny-pages/prestations/prestations.component';
import {RecrutementComponent} from '../components/tatynanny-pages/recrutement/recrutement.component';
import {TarifsComponent} from '../components/tatynanny-pages/tarifs/tarifs.component';
import {TermsComponent} from '../components/terms/terms.component';

import { HomePage } from './home.page';
import {AboutusAppComponent} from '../components/aboutus-app/aboutus-app.component';
import {AskProposalComponent} from './ask-proposal/ask-proposal.component';
import {TatynannyComponent} from './tatynanny/tatynanny.component';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
  },
  { path: 'tatynanny', component: TatynannyComponent},
  { path: 'faq', component: FaqComponent},
    // children: [
    //   {path: 'tatynanny', component: TatynannyComponent},
    //   {path: 'periscolaire', component: PeriscolaireComponent},
    //   {path: 'occasionnel', component: OccasionnelComponent},
    //   {path: 'ludique', component: LudiqueComponent},
    //   {path: 'soutienscolaire', component: SoutienComponent},
    //   {path: 'prestations', component: PrestationsComponent},
    //   {path: 'recrutement', component: RecrutementComponent},
    //   {path: 'accompagnement', component: AccompagnementComponent},
    //   {path: 'tarifs', component: TarifsComponent},
    //   {path: 'histoire', component: HistoireComponent},
    //   {path: 'valeur', component: ValeursComponent},
    //   {path: 'faq', component: FaqComponent},
    //   {path: 'partenaires', component: PartenairesComponent},
    //   {path: 'contact', component: ContactComponent},
    //   {path: 'legacy', component: LegacyComponent},
    //   {path: 'register', component: RegisterComponent},
    //   {path: 'terms', component: TermsComponent},
    //   {path: 'starter', component: StarterComponent},
    //   {path: 'demande-de-devis', component: AskProposalComponent},
    // ]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}
