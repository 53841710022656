
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Preferences} from '@capacitor/preferences';
import {IonContent, IonPopover, IonicSlides, ModalController, Platform} from '@ionic/angular';
import {SecurityService} from '@tatynanny/api';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Keyboard,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Zoom,
} from 'swiper';
import {NewsletterComponent} from '../components/newsletter/newsletter.component';
import {AlertService} from '../services/alert/alert.service';
import {EnvService} from '../services/environment/env.service';
import {KeycloakIonicService} from '../services/keycloak/keycloak-ionic.service';
import {SharedService} from '../services/shared/shared.service';
import {MatMenuTrigger} from '@angular/material/menu';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);
SwiperCore.use([EffectFade, IonicSlides]);

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {


  constructor(
    private keycloakService: KeycloakIonicService,
    private securityService: SecurityService,
    private alertService: AlertService,
    private envService: EnvService,
    private platform: Platform,
    private router: Router,
  ) {
  }


  ngOnInit() {
    this.refresh();
  }

  refresh(): void {
    if(!this.router.url.includes('demande-de-devis')) {
      this.router.navigateByUrl('/home/tatynanny');
    }
  }

  scroll(el: any) {
    console.log(el);
    if(this.router.url != '/home/tatynanny') {
      this.router.navigateByUrl('/home/tatynanny');
    }

    if (el == 'agency') {
      console.log(document.getElementById('agency'));
      document.getElementById('agency').scrollIntoView({
        behavior: 'smooth',
      });
    } else if (el == 'history') {
      console.log(document.getElementById('history'));
      document.getElementById('history').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } else if (el == 'service') {
      document.getElementById('service').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  redirectToNanny(): void {
    window.location.href = `${this.envService.frontBabysitterUrl}`;
  }

  login(): void {
    this.keycloakService.login();
  }

  navigateHome(): void {
    this.router.navigateByUrl('/home/tatynanny', {
      replaceUrl: true,
    });
  }
}
