import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RequestForEstimate, Pricing, LangOptions, RegisterAccountForm, RfeService} from '@tatynanny/api';
import {AlertService} from 'src/app/services/alert/alert.service';
import {ErrorService} from 'src/app/services/error/error.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {KeycloakSocialLoginService} from 'src/app/services/keycloak/keycloak-social-login.service';

@Component({
  selector: 'app-create-estimate',
  templateUrl: './create-estimate.component.html',
  styleUrls: ['./create-estimate.component.scss'],
})
export class CreateEstimateComponent implements OnInit {


  segment: 'SERVICE' | 'NEEDS' = 'SERVICE';
  segmentService: 'EXTRACURRICULAR' | 'EDUCATIONAL_SUPPORT' | 'MAID' | "" = "";
  minAge: "LESS_1" | 'BETWEEN_1_TO_2' | 'BETWEEN_3_TO_6' | 'MORE_6' | "" = ""
  nbChild: 1 | 2 | 3 | 0 = 0;
  school: 'PRIMARY' | 'COLLEGE' | 'HIGH SCHOOL' | '' = '';
  progress: number = 0.33;

  rfe: RequestForEstimate = {
    pricing: {} as Pricing,
    timeList: [],
    availableDuringHolidays: false,
    startDate: "",
    increaseLang: false,
    increaseChildren: false,
    langOptions: LangOptions.Anglais,
  } as RequestForEstimate;

  constructor(
    private rfeService: RfeService,
    private alertService: AlertService,
    private router: Router,
    private kcService: KeycloakIonicService,
    private errorService: ErrorService,
  ) { }
  
  ngOnInit() {
    if (localStorage.getItem('selectedDays')) {
      localStorage.removeItem('selectedDays');
    }
    console.log('[ask-proposal] (ngOnInit) rfe', this.rfe);
  }

  addProgress(value: number): void {
    this.progress = this.progress + value;
  }

  selectService(service: any): void {
    console.log(service);
    if (this.segmentService == service) {
      this.segmentService = "";
    } else {
      this.segmentService = service;
    }
  }
  selectChildren(number: any): void {
    if (this.nbChild == number) {
      this.nbChild = 0;
    } else {
      this.nbChild = number;
    }
  }
  selectMinAge(age: any): void {
    if (this.minAge == age) {
      this.minAge = "";
    } else {
      this.minAge = age;
    }
  }
  selectSchool(school: any): void {
    if (this.school == school) {
      this.school = "";
    } else {
      this.school = school;
    }
  }

  next(rfe?: RequestForEstimate): void {
    this.rfe.pricing = rfe.pricing;
    if (this.segment == 'SERVICE') {
      this.segment = 'NEEDS';
    }
  }


  back(rfe?: RequestForEstimate): void {
    console.log('[ask-proposal] (back) rfe', rfe);
    this.rfe = rfe;
    if(this.segment == 'NEEDS') {
      this.segment = 'SERVICE';
    }
  }

  validateRfe(rfe: any) {
    console.log('[create-estimate] (validateRfe)', rfe);

    if(this.rfe.nbChild >= 3) {
      this.rfe.increaseChildren = true;
    }
    
    this.rfe.percentChild = 10.0;
    this.rfe.percentLang = 10.0;

    this.rfeService.askRfe(rfe).subscribe(res => {
      console.log('[create-estimate] (askRfe)', res);
      if(res.status) {
        this.alertService.success('Demande de devis envoyé');
        this.router.navigateByUrl('/mon-espace/demandes', {
          replaceUrl: true
        });
      }
    }, (error) => {
      this.alertService.error("Une erreur est survenue lors de la demande de devis. Veuillez réessayez ultérieurement.")
    })
    //TODO: send rfe to create
  }

}
