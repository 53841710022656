import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-modal-rfe',
  templateUrl: './modal-rfe.component.html',
  styleUrls: ['./modal-rfe.component.scss'],
})
export class ModalRfeComponent implements OnInit {

  daysList : any[];
  List : any;
  start : string;
  end : string;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    console.log("Tablist",this.List)
    console.log("daylist",this.daysList)
    this.start = this.List[0].startTime;
    this.end = this.List[0].endTime;
  }

  dismissModal(): void {
    this.modalCtrl.dismiss({
      choiceDays : this.daysList
    });
  }

  validateSelection() {
    this.dismissModal();
  }

}
