import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuController, Platform} from '@ionic/angular';
import {BillService, FrontNotificationResourceService, FrontparentService, Notification} from '@tatynanny/api';
import * as moment from 'moment';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.page.html',
  styleUrls: ['./board.page.scss'],
})
export class BoardPage implements OnInit {


  innerHeight: number;
  innerWidth: number;
  itemHeight: number;
  contentWidth: number;
  nbNotif: number = 0;
  currentDate = moment();
  notifications: Notification[] = [];
  sliceValue: number = 10;
  connected: boolean = false;

  constructor(
    private router: Router,
    private keycloakIonicService: KeycloakIonicService,
    private notificationService: FrontNotificationResourceService,
    private parentService: FrontparentService,
    private alertService: AlertService,
    private invoiceService: BillService,
    private platform: Platform,
  ) { }


  loadData(event): void {
    console.log(event);

    setTimeout(() => {
      console.log('Done');
      event.target.complete();
      this.sliceValue = this.sliceValue + 5;
      if (this.sliceValue >= this.notifications.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  navigation(notification: Notification): void {
    this.notificationService.readNotification(notification.id).subscribe(res => {
      console.log(res);
      this.refresh();
    })

    if (notification.type == 'proposal') {
      this.router.navigateByUrl(`/mon-espace/devis/detail/${notification.itemId}`);
    } else if (notification.type == 'contract') {
      this.parentService.loadSubContractById(notification.itemId).forEach(r => {
        console.log('CONTRACT LOADED :', r);
        if (r.parentContract.contractNanny.pricing.productType === 'EXTRACURRICULAR') {
          if (!r.candidate.autoEntreprise) {
            this.router.navigateByUrl(`/mon-espace/contrats/detail-contrat/${notification.itemId}/salarie`);
          } else {
            this.router.navigateByUrl(`/mon-espace/contrats/detail-contrat-ae/${notification.itemId}`);
          }
        }
        if (r.parentContract.contractNanny.pricing.productType === 'MAID') {
          this.alertService.confirm('Contrat', 'Le contrat est en cours de réalisation par l\'équipe TatyNanny');
        }
        if (r.parentContract.contractNanny.pricing.productType === 'EDUCATIONAL_SUPPORT') {
          this.alertService.confirm('Contrat', 'Le contrat est en cours de réalisation par l\'équipe TatyNanny');
        }
      });
    } else if (notification.type == 'bill') {

      this.invoiceService.loadBillById(notification.itemId).forEach(r => {
        if (r.result.userType == 'SALARIED') {
          this.router.navigateByUrl(`/mon-espace/factures/invoice-detail/${notification.itemId}`);
        } else {
          console.log('AUTO ENTREPRONNEUR');
          this.router.navigateByUrl(`/mon-espace/factures/invoice-detail-ae/${notification.itemId}`);
        }
      });
    } else if (notification.type == 'timesheet') {
      this.router.navigateByUrl(`/mon-espace/feuille-de-temps/event/${notification.itemId}`);
    }
  }

  deleteNotif(notification: Notification): void {
    this.alertService.ask('Attention', 'Voulez-vous supprimer définitivement la notification ?', 'Oui, supprimer', () => {
      this.notificationService.deleteNotification(notification.id).subscribe(res => {
        console.log(res);
        this.refresh();
      })
    }, 'Annuler', () => {

    })
  }

  ngOnInit() {
    this.innerHeight = window.innerHeight;
    this.refresh();
  }

  refresh(): void {
    this.nbNotif = 0;
    this.notifications = [];
    
    this.keycloakIonicService.isLoggedIn().then(connected => {
      this.connected = connected;
    })

    if (this.platform.platforms().includes('mobile') || this.platform.platforms().includes('mobileweb')) {
      this.notificationService.getNotifications().subscribe(res => {
        console.log(res);
        res.items.sort(function (a, b) {
          if (a.creationDate >= b.creationDate) {
            return -1;
          } else {
            return 1;
          }
        });
        for (let item of res.items) {
          if (item.read == false) {
            this.nbNotif = this.nbNotif + 1;
          }
          if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'weeks') > 0) {
            item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'weeks').toString() + 'sem';
          } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'days') > 0) {
            item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'days').toString() + 'j';
          } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'hours') > 0) {
            item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'hours').toString() + 'h';
          } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'minutes') > 0) {
            item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'minutes').toString() + 'min';
          } else if (this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'seconds') > 0) {
            item.creationDate = this.currentDate.diff(moment(item.creationDate).subtract(1, 'hours'), 'seconds').toString() + 's';
          }
          this.notifications.push(item);
        }
      })
    }
  }

  navigateHome(): void {
    this.router.navigateByUrl('/home/tatynanny', {
      replaceUrl: true,
    })
  }

  logout(): void {
    this.keycloakIonicService.logout();
  }


}
