import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MinAgeInterval, Pricing, RequestForEstimate} from '@tatynanny/api';

@Component({
  selector: 'app-ask-proposal-service',
  templateUrl: './ask-proposal-service.component.html',
  styleUrls: ['./ask-proposal-service.component.scss'],
})
export class AskProposalServiceComponent implements OnInit {

  segmentService: any = "";
  minAge: MinAgeInterval;
  nbChild: number = 0;
  school: any = '';
  @Input('rfe') rfe: RequestForEstimate;
  @Output() nextEv = new EventEmitter();

  @ViewChild('children', {static: true}) children: ElementRef;
  @ViewChild('age', {static: true}) age: ElementRef;
  @ViewChild('schoolLevel', {static: true}) schoolLevel: ElementRef;

  constructor() { }

  ngOnInit() {
    console.log('[ask-proposal-service] (ngOnInit) rfe', this.rfe);

    if (this.rfe.nbChild > 0) {
      this.nbChild = this.rfe.nbChild
    }
    if (this.rfe.minAgeInterval) {
      this.minAge = this.rfe.minAgeInterval;
    }
    if (localStorage.getItem('classLevel')) {
      this.school = localStorage.getItem('classLevel');
    }
  }

  next(): void {
    this.rfe.nbChild = this.nbChild;
    this.rfe.minAgeInterval = this.minAge;

    console.log('[ask-proposal-service] (next) rfe', this.rfe);
    this.nextEv.emit(this.rfe);
  }

  selectService(service: any): void {
    this.rfe.pricing.productType = service;
    if (service != 'EDUCATIONAL_SUPPORT') {
      localStorage.removeItem('classLevel');
      this.school = "";
    }

    if(service == 'EXTRACURRICULAR') {
      setTimeout(() => {
        let element: any = document.getElementById('children');
        element.scrollIntoView({behavior: 'smooth'});
      }, 300);
    } else if(service == 'EDUCATIONAL_SUPPORT') {
      setTimeout(() => {
        let element: any = document.getElementById('schoolLevel');
        element.scrollIntoView({behavior: 'smooth'});
      }, 300);
    }
  }
  selectChildren(number: any): void {
    if (this.nbChild == number) {
      this.nbChild = 0;
    } else {
      this.nbChild = number;
    }

    setTimeout(() => {
      let element: any = document.getElementById('age');
      element.scrollIntoView({behavior: 'smooth'});
    }, 300);
  }
  selectMinAge(age: any): void {
    if (this.minAge == age) {
      this.minAge = null;
    } else {
      this.minAge = age;
    }

    setTimeout(() => {
      let element: any = document.getElementById('validate');
      element.scrollIntoView({behavior: 'smooth'});
    }, 300);
  }
  selectSchool(school: any): void {
    if (this.school == school) {
      this.school = "";
      localStorage.removeItem('classLevel');
    } else {
      this.school = school;
      localStorage.setItem('classLevel', this.school);
    }

    setTimeout(() => {
      let element: any = document.getElementById('validate');
      element.scrollIntoView({behavior: 'smooth'});
    }, 300);
  }
}
