import {Component, OnInit, ViewChild} from '@angular/core';
import {Account, Candidate, FrontparentService, Parent, ParentContract, Proposal, SubContract, SuitableTime} from "@tatynanny/api";
import {ActivatedRoute, Router} from "@angular/router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as moment from "moment";
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';

@Component({
  selector: 'app-detail-contrat',
  templateUrl: './detail-contrat.page.html',
  styleUrls: ['./detail-contrat.page.scss'],
})
export class DetailContratPage implements OnInit {

  detailId: any;
  year: any;
  loaded: boolean = false;

  title: string;
  contract: SubContract;
  proposal: Proposal;
  parent: Parent;

  timeList: any[];
  reqForEstimate: any;

  // Costs :
  totalTTC: number = 0;
  CAF: number = 377.66;
  totalAfterCaf: number = 0;
  impot: number = 0;
  totalAfterImpot: number = 0;
  totalNet: number = 0;

  hoursWeek: any;
  minutesWeek: any;
  minutesMonth: any;

  holidays: boolean;

  @ViewChild('proposalDiv') proposalDiv;
  @ViewChild('proposalPage1') proposalPage1;
  @ViewChild('proposalPage2') proposalPage2;
  @ViewChild('proposalPage3') proposalPage3;
  @ViewChild('proposalPage4') proposalPage4;
  @ViewChild('proposalPage5') proposalPage5;

  taux = 0;
  constructor(
    private parentService: FrontparentService,
    private router: Router,
    private alertService: AlertService,
    private keycloakService: KeycloakIonicService,
    private activatedRoute: ActivatedRoute,

  ) { }

  minute: any = 0;

  ngOnInit() {
    this.alertService.loading();
    this.keycloakService.isLoggedIn().then(connected => {
      if (connected) {
        this.year = new Date().getFullYear();
        this.detailId = this.activatedRoute.snapshot.paramMap.get('id');
        this.parentService.loadSubContractById(this.detailId).forEach(r => {
          console.log('CONTRACT :', r);
          this.contract = r;
          this.proposal = r.parentContract.proposal;
          this.title = r.parentContract.contractNanny.pricing.productType;
          this.parent = r.parentContract.contractNanny.parent;
          this.holidays = r.parentContract.contractNanny.availableDuringHolidays;
          this.timeList = r.timeList;
          // this.contract.parentContract.contractNanny = r.proposal.requestForEstimateLink;

          if (this.contract.increaseChildren == true) {
            this.taux = this.taux + (this.contract.percentChild != null ? this.contract.percentChild : 0);
          }
          if (this.contract.increaseLang == true) {
            this.taux = this.taux + (this.contract.percentLang != null ? this.contract.percentLang : 0);
          }

          this.minutesMonth = (r.parentContract.contractNanny.totalHoursPerMonth % 1) * 60;

          this.hoursWeek = r.totalHoursPerWeek;
          if (this.isFloat(this.hoursWeek)) {
            this.minutesWeek = ((this.hoursWeek % 1) * 60).toFixed(0);
            console.log('MINUTE:', this.minute);
            this.hoursWeek = this.hoursWeek - (this.hoursWeek % 1);
          }

          this.totalTTC = this.contract.parentContract.contractNanny.totalPriceTTC;
          if (this.title == 'EXTRACURRICULAR') {
            /*if(this.contract.parent.preferredLang == 'FRANCAIS' || this.contract.parent.preferredLang == null ) {
              if(this.parent.nbEnfants >= 3) {
                this.totalTTC = this.contract.parentContract.contractNanny.totalPriceTTC * 1.1 ;
              } else {
                this.totalTTC = this.contract.parentContract.contractNanny.totalPriceTTC;
              }
            } else {
              if(this.parent.nbEnfants >= 3) {
                this.totalTTC = this.contract.parentContract.contractNanny.totalPriceTTC * 1.21 ;
              }
              else {
                this.totalTTC = this.contract.parentContract.contractNanny.totalPriceTTC * 1.1;
              }
            }*/
            this.totalAfterCaf = this.totalTTC - this.CAF;
            this.impot = this.totalAfterCaf / 2;
            this.totalAfterImpot = this.totalAfterCaf - this.impot;
          }
          else {
            console.log("REEQ", this.contract.parentContract.contractNanny);
            this.totalTTC = this.contract.parentContract.contractNanny.totalPriceTTC;
            this.totalAfterCaf = 0;
            this.impot = this.totalTTC / 2;
            this.totalAfterImpot = this.impot;
          }

          this.totalNet = this.totalAfterImpot / this.contract.parentContract.contractNanny.totalHoursPerMonth;

        })
        this.loaded = true;
        this.alertService.closeLoader();

      } else {
        this.alertService.closeLoader();
        //this.alertService.temp('Vous êtes déconnecté.', 2000);
        this.router.navigateByUrl('/home/tatynanny', {
          replaceUrl: true,
        })
      }
    })
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  async generatePdf() {
    const imgWidth = 210;
    const pageHeight = 295;
    const position = 0;
    let imgHeight = 0;
    const doc = new jsPDF('p', 'mm');
    const image1 = await this.getImageData(this.proposalPage1.nativeElement);
    imgHeight = image1.height * imgWidth / image1.width;
    doc.addImage(image1.url, 'PNG', 0, position, imgWidth, imgHeight);
    await this.generateElement2pdf(this.proposalPage2.nativeElement, doc);
    await this.generateElement2pdf(this.proposalPage3.nativeElement, doc);
    await this.generateElement2pdf(this.proposalPage4.nativeElement, doc);
    await this.generateElement2pdf(this.proposalPage5.nativeElement, doc);
    doc.save('Contrat-' + this.contract.contractId + '.pdf');
  }

  async getImageData(element) {
    const canvas = await html2canvas(element);
    return {url: canvas.toDataURL("image/jpeg"), height: canvas.height, width: canvas.width}
  }

  async generateElement2pdf(element, doc: jsPDF) {
    const canvas = await this.getImageData(element)
    const image = canvas.url;
    const imgWidth = 210;
    const pageHeight = 295;
    let position = 0;
    let imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;
    doc.addPage();
    doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight - 5);
      heightLeft -= pageHeight;
    }
  }
}
