import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-aboutus-app',
  templateUrl: './aboutus-app.component.html',
  styleUrls: ['./aboutus-app.component.scss'],
})
export class AboutusAppComponent implements OnInit {

  realIndex: number = 0;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

  onSlideChange(event: any): void {
    console.log(event);
    this.realIndex = event[0].realIndex;
  }
}
