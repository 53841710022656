import {MapsAPILoader} from '@agm/core';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {IonInput} from '@ionic/angular';
import {AccountType, RegisterAccountForm, RequestForEstimate, RfeService} from '@tatynanny/api';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {KeycloakSocialLoginService} from 'src/app/services/keycloak/keycloak-social-login.service';
import {Keyboard} from '@capacitor/keyboard';

@Component({
  selector: 'app-ask-proposal-info',
  templateUrl: './ask-proposal-info.component.html',
  styleUrls: ['./ask-proposal-info.component.scss'],
})
export class AskProposalInfoComponent implements OnInit, AfterViewInit {

  @Input('rfe') rfe: RequestForEstimate;
  @Output() backEv = new EventEmitter();

  @Output() registerEv = new EventEmitter();
  @Output() registerSocialEv = new EventEmitter();

  registerForm: RegisterAccountForm = {
    accountType: AccountType.Client,
  } as RegisterAccountForm;

  @ViewChild('address', {static: true}) addressRef: IonInput;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    console.log('[ask-proposal-info] (ngOnInit) rfe', this.rfe);
    this.registerForm.requestForEstimate = this.rfe;
  }

  ngAfterViewInit(): void {
    this.addressRef.getInputElement().then(input => {
      this.mapsAPILoader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(
          input,
          {
            types: ['address'],
            componentRestrictions: {
              country: 'fr',
            },
          }
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.registerForm.formattedAddress = place.formatted_address;
            this.registerForm.addressNumber =
              place.address_components[0].long_name;
            this.registerForm.address = place.address_components[1].long_name;
            this.registerForm.city = place.address_components[2].long_name;
            if (place.address_components[5] != null) {
              this.registerForm.country = place.address_components[5].long_name;
            }
            if (place.address_components[6] != null) {
              this.registerForm.zipcode = place.address_components[6].long_name;
            }
            this.registerForm.latitude = place.geometry.location.lat();
            this.registerForm.longitude = place.geometry.location.lng();
          });
        });
      });
    });
  }

  back(): void {
    this.backEv.emit(this.rfe);
  }


  checkForm(): boolean {
    let valid = true;
    if (!this.registerForm.lastname) valid = false;
    if (!this.registerForm.phoneNumber) valid = false;
    if (!this.registerForm.email) valid = false;
    if (!this.registerForm.password) valid = false;
    if (!this.registerForm.formattedAddress) valid = false;

    return valid;
  }

  finalize(): void {
    this.registerEv.emit(this.registerForm);
  }

  // selectRegister(type: any): void {
  //   let object = {
  //     type: type,
  //     registerForm: this.registerForm,
  //   }
  //   this.registerSocialEv.emit(object);
  // }

  handleEnter(event: any): void {
    console.log(event);
    Keyboard.hide();
  }
}
