import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {RequestForEstimate, RfeService} from '@tatynanny/api';
import * as moment from 'moment';
import {ModalDayofweekComponent} from 'src/app/components/modal-dayofweek/modal-dayofweek.component';
import {AlertService} from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-ask-proposal-needs',
  templateUrl: './ask-proposal-needs.component.html',
  styleUrls: ['./ask-proposal-needs.component.scss'],
})
export class AskProposalNeedsComponent implements OnInit {

  @Input('rfe') rfe: RequestForEstimate;

  @Input('connected') connected: boolean = false;

  @Output() nextEv = new EventEmitter();
  @Output() validateEv = new EventEmitter();
  @Output() backEv = new EventEmitter();

  daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  selectedDays: any[] = [
    {dayOfWeek: 1, day: 'Lundi', startTime: '16:30', endTime: '19:30', frequency: 'WEEKLY', checked: false, canDelete: false},
    {dayOfWeek: 2, day: 'Mardi', startTime: '16:30', endTime: '19:30', frequency: 'WEEKLY', checked: false, canDelete: false},
    {dayOfWeek: 3, day: 'Mercredi', startTime: '11:30', endTime: '19:30', frequency: 'WEEKLY', checked: false, canDelete: false},
    {dayOfWeek: 4, day: 'Jeudi', startTime: '16:30', endTime: '19:30', frequency: 'WEEKLY', checked: false, canDelete: false},
    {dayOfWeek: 5, day: 'Vendredi', startTime: '16:30', endTime: '19:30', frequency: 'WEEKLY', checked: false, canDelete: false},
    {dayOfWeek: 6, day: 'Samedi', startTime: '16:30', endTime: '19:30', frequency: 'WEEKLY', checked: false, canDelete: false},
    {dayOfWeek: 7, day: 'Dimanche', startTime: '16:30', endTime: '19:30', frequency: 'WEEKLY', checked: false, canDelete: false},
  ];

  estimate: RequestForEstimate = {
    budgetBeforeAid: 0.0,
    budgetAfterCaf: 0.0,
    cafHelp: 0.0,
    taxCredits: 0.0,
    realCost: 0.0,
    totalHoursPerWeekStr: "",
    totalHoursPerMonthStr: "",
    totalHoursPerMonth: 0.0,
    pricePerHour: 0.0,
    increaseChildren: false,
  }
  error: string = "";

  minDate: any;

  constructor(
    private modalCtrl: ModalController,
    private rfeService: RfeService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
    if (localStorage.getItem('selectedDays')) {
      this.selectedDays = JSON.parse(localStorage.getItem('selectedDays'));
      this.refreshEstimate();
    } else if (this.rfe.timeList.length > 0) {
      this.loadRfe(this.rfe);
      this.refreshEstimate();
    }

    if (!this.rfe.startDate) {
      this.rfe.startDate = moment().locale('fr').add(1, 'days').format('YYYY-MM-DD');
    }
  }

  loadRfe(rfe: RequestForEstimate): void {
    this.selectedDays = [];
    rfe.timeList.forEach((time, index) => {
      let canDelete: boolean = false;

      // let indexFound = this.selectedDays.findIndex(e => e.dayOfWeek == time.dayOfWeek && e.checked == true);
      let indexFound = this.selectedDays.filter(e => e.dayOfWeek == time.dayOfWeek && e.checked == true);
      if (indexFound.length > 1) {
        canDelete = true;
      }
      this.selectedDays.push({
        dayOfWeek: time.dayOfWeek,
        day: this.daysOfWeek[time.dayOfWeek > 0 ? time.dayOfWeek - 1 : 0],
        startTime: time.startTime,
        endTime: time.endTime,
        frequency: time.frequency,
        checked: true,
        canDelete: canDelete,
      })
    });


    this.daysOfWeek.forEach((day, index) => {
      let found = this.selectedDays.find(e => e.day == day);
      if (!found) {
        this.selectedDays.push({
          dayOfWeek: index + 1,
          day: day,
          startTime: day != 'Mercredi' ? '16:30' : '14:30',
          endTime: '19:30',
          frequency: 'WEEKLY',
          checked: false,
          canDelete: false,
        });
      }
    });

    this.sortDays();
  }

  addDay(day: string, checked?: boolean) {
    let startTime = "16:30";
    let endTime = "19:30";
    if (day == 'Mercredi') {
      startTime = "11:30";
      endTime = "19:30";
    }
    this.selectedDays.push({
      dayOfWeek: this.getDayOfWeek(day),
      day: day,
      startTime: startTime,
      endTime: endTime,
      frequency: 'WEEKLY',
      checked: checked || false,
    });


    this.sortDays();
  }

  removeDay(day: string) {
    const index = this.selectedDays.findIndex(d => d.day === day);
    if (index !== -1) {
      this.selectedDays.splice(index, 1);
    }
  }

  addExtraDay(day?: string) {
    console.log(day);
    this.addDay(day, true);
  }

  async openModalDayOfWeek() {

    const modal = await this.modalCtrl.create({
      component: ModalDayofweekComponent,
      cssClass: 'modal-calendar',
    });
    modal.onDidDismiss().then(res => {
      if (res.data) {
        console.log(res.data);
        res.data.forEach(r => {
          let index = this.selectedDays.findIndex(e => e.dayOfWeek == r);
          if (this.selectedDays[index].checked) {
            this.selectedDays.push({
              dayOfWeek: r,
              day: this.getDay(r),
              startTime: '16:30',
              endTime: '19:30',
              frequency: 'WEEKLY',
              checked: true,
              canDelete: true,
            });
            this.sortDays();
          } else {
            this.selectedDays[index].checked = true;
          }
        });
      }
    })
    await modal.present();
  }

  sortDays(): void {
    this.selectedDays.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
  }

  safeDelete(index: number): void {
    this.selectedDays.splice(index, 1);
  }

  getDayOfWeek(day: string): number {
    if (day == 'Lundi') {
      return 1;
    } else if (day == 'Mardi') {
      return 2;
    } else if (day == 'Mercredi') {
      return 3;
    } else if (day == 'Jeudi') {
      return 4;
    } else if (day == 'Vendredi') {
      return 5;
    } else if (day == 'Samedi') {
      return 6;
    } else if (day == 'Dimanche') {
      return 7;
    }
  }
  getDay(dayOfWeek: number): string {
    if (dayOfWeek == 1) {
      return 'Lundi';
    } else if (dayOfWeek == 2) {
      return 'Mardi';
    } else if (dayOfWeek == 3) {
      return 'Mercredi';
    } else if (dayOfWeek == 4) {
      return 'Jeudi';
    } else if (dayOfWeek == 5) {
      return 'Vendredi';
    } else if (dayOfWeek == 6) {
      return 'Samedi';
    } else if (dayOfWeek == 7) {
      return 'Dimanche';
    }
  }

  checkSelectedDays(): boolean {
    let element = this.selectedDays.find(e => e.checked == true);
    if (element != null && this.rfe.startDate && moment(this.rfe.startDate).locale('fr').isAfter(moment().locale('fr'))) {
      return true;
    } else {
      return false;
    }
  }

  next(): void {
    if (this.checkSelectedDays()) {
      this.selectedDays.forEach(e => {
        if (e.checked) {
          this.rfe.timeList.push({
            dayOfWeek: e.dayOfWeek,
            startTime: e.startTime,
            endTime: e.endTime,
            frequency: e.frequency,
          });
        }
      });
      console.log('[ask-proposal-needs] (next) rfe', this.rfe);
      localStorage.setItem('selectedDays', JSON.stringify(this.selectedDays));
      this.refreshEstimate();
      this.estimate.increaseChildren = this.rfe.increaseChildren;
      this.estimate.increaseLang = this.rfe.increaseLang;
      this.estimate.percentLang = 10.0;
      this.estimate.percentChild = 10.0;
      this.validateEv.emit(this.estimate);
    } else {
      this.alertService.temp('Veuillez sélectionnez au moins un créneau horaire.', 3000);
    }

  }

  back(): void {
    this.backEv.emit(this.rfe);
  }

  handleCheckboxChange(event: any, index: number) {
    this.selectedDays[index].checked = event.detail.checked;
    if (this.selectedDays[index].canDelete) {
      this.selectedDays.splice(index, 1);
    }
    this.refreshEstimate();
  }


  refreshEstimate(): void {
    this.rfe.timeList = [];
    this.selectedDays.forEach(element => {
      if (element.checked) {
        this.rfe.timeList.push({
          dayOfWeek: element.dayOfWeek,
          startTime: element.startTime,
          endTime: element.endTime,
          frequency: element.frequency,
        });
      }
    });
    this.rfeService.getEstimateRfe(this.rfe).subscribe(res => {
      console.log('[ask-proposal-needs] (getEstimateRfe)', res);
      this.estimate = res.result;
    });
  }
}