import {MatPaginatorIntl} from '@angular/material/paginator';

export class CustomPaginatorConfig extends MatPaginatorIntl {
  itemsPerPageLabel = "Élements par page: ";
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';
  firstPageLabel = 'Première page';
  lastPageLabel = 'Dernière page';

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return '';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    if (startIndex >= length) {
      return `${length} sur ${length}`;
    }
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} sur ${length}`;
  };
}