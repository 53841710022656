import { Component, OnInit } from '@angular/core';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  constructor(
    private keycloakService: KeycloakIonicService
  ) { }

  ngOnInit() {}

  login(): void {
    this.keycloakService.login();
  }
}
