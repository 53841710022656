import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './services/guard/auth-guard.service';
import {HomePage} from './home/home.page';
import {HistoireComponent} from './components/about-pages/histoire/histoire.component';
import {PartenairesComponent} from './components/about-pages/partenaires/partenaires.component';
import {ValeursComponent} from './components/about-pages/valeurs/valeurs.component';
import {ContactComponent} from './components/contact/contact.component';
import {FaqComponent} from './components/faq/faq.component';
import {LegacyComponent} from './components/legacy/legacy.component';
import {LudiqueComponent} from './components/prestations-pages/ludique/ludique.component';
import {OccasionnelComponent} from './components/prestations-pages/occasionnel/occasionnel.component';
import {PeriscolaireComponent} from './components/prestations-pages/periscolaire/periscolaire.component';
import {SoutienComponent} from './components/prestations-pages/soutien/soutien.component';
import {RegisterComponent} from './components/register/register.component';
import {StarterComponent} from './components/starter/starter.component';
import {AccompagnementComponent} from './components/tatynanny-pages/accompagnement/accompagnement.component';
import {PrestationsComponent} from './components/tatynanny-pages/prestations/prestations.component';
import {RecrutementComponent} from './components/tatynanny-pages/recrutement/recrutement.component';
import {TarifsComponent} from './components/tatynanny-pages/tarifs/tarifs.component';
import {TermsComponent} from './components/terms/terms.component';
import {AskProposalComponent} from './home/ask-proposal/ask-proposal.component';
import {TatynannyComponent} from './home/tatynanny/tatynanny.component';
import {BoardPage} from './connected/board/board.page';
import {EstimateComponent} from './connected/board/profilePages/estimate/estimate.component';
import {EstimateDetailComponent} from './connected/board/profilePages/estimate/estimate-detail/estimate-detail.component';
import {CreateEstimateComponent} from './connected/board/profilePages/estimate/create-estimate/create-estimate.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomePage,
    children: [
      {path: 'tatynanny', component: TatynannyComponent},
      {path: 'periscolaire', component: PeriscolaireComponent},
      {path: 'occasionnel', component: OccasionnelComponent},
      {path: 'ludique', component: LudiqueComponent},
      {path: 'soutienscolaire', component: SoutienComponent},
      {path: 'prestations', component: PrestationsComponent},
      {path: 'recrutement', component: RecrutementComponent},
      {path: 'accompagnement', component: AccompagnementComponent},
      {path: 'tarifs', component: TarifsComponent},
      {path: 'histoire', component: HistoireComponent},
      {path: 'valeur', component: ValeursComponent},
      {path: 'faq', component: FaqComponent},
      {path: 'partenaires', component: PartenairesComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'legacy', component: LegacyComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'terms', component: TermsComponent},
      {path: 'starter', component: StarterComponent},
      {path: 'demande-de-devis', component: AskProposalComponent},
    ]
  },
  {
    path: 'mon-espace',
    component: BoardPage,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'tableau-de-bord',
        loadChildren: () => import('./connected/board/dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'profil',
        loadChildren: () => import('./connected/board/profilePages/profile/profile.module').then( m => m.ProfilePageModule)
      },
      {
        path: 'description',
        loadChildren: () => import('./connected/board/profilePages/info/info.module').then( m => m.InfoPageModule)
      },
      {
        path: 'documents',
        loadChildren: () => import('./connected/board/profilePages/docs/docs.module').then( m => m.DocsPageModule)
      },
      {
        path: 'contrats',
        loadChildren: () => import('./connected/board/contract/contract.module').then( m => m.ContractPageModule)
      },
      {
        path: 'feuille-de-temps',
        loadChildren: () => import('./connected/board/timesheet/timesheet.module').then( m => m.TimesheetPageModule)
      },
      {
        path: 'factures',
        loadChildren: () => import('./connected/board/invoice/invoice.module').then( m => m.InvoicePageModule)
      },
      {
        path: 'attestation-fiscale',
        loadChildren: () => import('./connected/board/attestation/attestation.module').then( m => m.AttestationPageModule)
      },
      {
        path: 'banque',
        loadChildren: () => import('./connected/board/payment/payment.module').then( m => m.PaymentPageModule)
      },
      {
        path: 'rfe',
        loadChildren: () => import('./connected/board/profilePages/rfe/rfe.module').then( m => m.RfePageModule)
      },
      { path: 'demandes', component: EstimateComponent},
      { path: 'demandes/:id', component: EstimateDetailComponent},
      { path: 'nouvelle-demande', component: CreateEstimateComponent},
      {
        path: 'devis',
        loadChildren: () => import('./connected/board/devis/devis.module').then( m => m.DevisPageModule)
      },
    ]
  },
  {
    path: 'app-board',
    loadChildren: () => import('./connected/app-board/app-board.module').then(m => m.AppBoardPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'home/register/validate',
    loadChildren: () => import('./components/register/validate/validate.module').then(m => m.ValidatePageModule)
  },
  {
    path: 'home/validate/validate-fin',
    loadChildren: () => import('./components/register/validate-fin/validate-fin.module').then(m => m.ValidateFinModule)
  },
  {
    path: 'register-social',
    loadChildren: () => import('./components/register/register-social/register-social.module').then(m => m.RegisterSocialPageModule)
  },
  {
    path: 'register-social/:id',
    loadChildren: () => import('./components/register/register-social/register-social.module').then(m => m.RegisterSocialPageModule)
  },
  {
    path: '',
    redirectTo: 'home/tatynanny',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
