import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datePipe'
})
export class DatePipePipe implements PipeTransform {

  transform(value: any): any {
    try {
      let date = moment(value.date).format('W');
      return date;
    } catch {
      return null;
    }
  }

}
