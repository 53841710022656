import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {AboutusAppComponent} from '../aboutus-app/aboutus-app.component';

@Component({
  selector: 'app-starter',
  templateUrl: './starter.component.html',
  styleUrls: ['./starter.component.scss'],
})
export class StarterComponent implements OnInit {

  constructor(
    private keycloakService: KeycloakIonicService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  login(): void {
    this.keycloakService.login();
  }

  async openAboutUsApp() {
    const modal = await this.modalCtrl.create({
      component: AboutusAppComponent,
      backdropDismiss: false,
    });
    modal.present();
  }

}
