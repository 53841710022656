import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  // public apiUrl = 'https://api-back.testing.tatynanny.com';
  // public apiUrl = "https://api.tatynanny.com"
  // public apiUrl = "https://api-features-ci-fabien.testing.tatynanny.com";
  public apiUrl = "http://localhost:9191";
  public frontBabysitterUrl = "https://www.tatynannyjobs.com";
  public frontClientUrl = "https://www.tatynanny.com";
  public frontTest = "https://features-build-client.testing.tatynanny.com";
  //public apiUrl =" https://api-features-ci-fabien.testing.tatynanny.com";
  public ionicDeployChannel = '';
  public keycloak = {
    // url: 'https://sso-testing.testing.tatynanny.com/',
    // url: 'https://sso.preprod.tatynanny.com/auth',
    url: 'http://localhost:8180',
    //url :'https://sso-testing.testing.tatynanny.com/',
    realm: 'tatynanny',
    // realm: 'tatynanny',
    clientId: 'front',
    // redirectUri: 'https://features-build-client.testing.tatynanny.com',
    // redirectUriBrowser: 'https://features-build-client.testing.tatynanny.com'
    redirectUri: 'http://localhost:8100',
    redirectUriBrowser: 'http://localhost:8100'
  };

  public enableDebug = true;
  public production = false

  constructor() { }
}
