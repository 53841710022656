import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import {Router} from "@angular/router";
import {FrontparentService} from "@tatynanny/api";
import {Subscription} from 'rxjs';
import {AlertService} from 'src/app/services/alert/alert.service';
import {EnvService} from 'src/app/services/environment/env.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {NavigationService} from 'src/app/services/navigation/navigation.service';
import {SharedService} from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-client-navigation',
  templateUrl: './client-navigation.component.html',
  styleUrls: ['./client-navigation.component.scss'],
})
export class ClientNavigationComponent implements OnInit {

  disabled: boolean = false;

  menuEventSubscription: Subscription;

  imgURL: string = '';

  constructor(
    private keycloakService: KeycloakIonicService,
    private alertService: AlertService,
    private router: Router,
    private parentService: FrontparentService,
    public navigationService: NavigationService,
    private sharedService: SharedService,
    private envService: EnvService,
  ) {
    this.menuEventSubscription = this.sharedService.getRefreshMenu().subscribe(() => {
      this.refresh();
    });
  }

  ngOnInit() {
    this.refresh();
  }

  refresh(): void {
    this.navigationService.refreshClient().then((n) => {
      if(n) {
        if(n.result.civilite == null || n.result.formattedAddress == null || n.result.nbEnfants == null || n.result.birthdate == null) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    })
  }

  logout(): void {
    this.keycloakService.logout();
  }

}
