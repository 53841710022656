import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {PageQuery, Proposal, ProposalService, SearchItem, TimeScheduleForm, TimeScheduleMonth, TimescheduleService, TimeScheduleStatus} from "@tatynanny/api";
import * as moment from 'moment';
import {merge} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.page.html',
  styleUrls: ['./timesheet.page.scss'],
})
export class TimesheetPage implements OnInit {

  segment: 'SENT' | 'ACTIVE' | 'ALL' = 'ALL';
  data: Proposal[] = [];
  displayedColumns: string[] = ["monthYear", "parentContract.contractNanny.pricing.productType", "candidate.account.lastname", "hoursCompleted", "calculatedPrice", "timeScheduleStatus", "action"];
  service: string = "";
  inputId: string = "";
  selectedDate: string = "";
  searchDate: string = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageQuery: PageQuery = {
    fields: [
      {field: "monthYear", value: this.searchDate},
      {field: "parentContract.contractNanny.pricing.productType", value: this.service},
      {field: "timeScheduleStatus", value: this.segment},
    ],
    sort: 'creationDate',
    direction: 'desc',
  }

  searchItems: SearchItem[] = [];

  constructor(
    private timesheetService: TimescheduleService,
    private router: Router,
  ) { }

  ngOnInit(): void {

  }

  refresh(): void {
    this.searchItems = [
      {field: "monthYear", value: this.searchDate},
      {field: "parentContract.contractNanny.pricing.productType", value: this.service},
      {field: "timeScheduleStatus", value: this.segment},
    ];
    this.pageQuery.fields = this.searchItems;
    this.timesheetService.searchTimesheet(this.pageQuery).subscribe((res: any) => {
      console.log('[timesheet] (search)', res);
      this.data = res.content;
    })
  }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.pageQuery.sort = this.sort.active;
      this.pageQuery.direction = this.sort.direction;
      console.log(this.sort.active);
    });
    this.pageQuery.page = this.paginator.pageIndex;
    this.pageQuery.pageSize = this.paginator.pageSize;

    merge(this.sort.sortChange, this.paginator.page).pipe(startWith({}), switchMap(() => {
      return this.timesheetService.searchTimesheet(this.pageQuery);
    })).subscribe((data: any) => {
      console.log('[timesheet] (search)', data);
      (this.data = data.content)
    });
  }

  onSegmentChange(event: any): void {
    console.log(event);
    this.segment = event;
    this.refresh();

  }

  onInputChange(event: any): void {
    console.log('[timesheet] (onInputChange)', event);
    this.inputId = event;
    this.refresh();
  }

  onServiceChange(event: any): void {
    console.log('[timesheet] (onServiceChange)', event);
    this.service = event.value;
    this.refresh();
  }

  onDateChange(event: any): void {
    console.log('[timesheet] (onDateChange)', event);
    this.selectedDate = event;
    if(event != '') {
      let dates = event.split('-');
      console.log(dates);
      if(dates && dates[1].startsWith('0')) {
        dates[1] = dates[1].substr(1);
      }
      this.searchDate = dates[1] + "-" + dates[0];
    } else {
      this.searchDate = event;
    }
    this.refresh();
  }

  openTimesheet(timesheet: TimeScheduleMonth): void {
    console.log('[timescheet] (openTimesheet)', timesheet);
    this.router.navigateByUrl(`/mon-espace/feuille-de-temps/event/${timesheet.id}`)
    
  }


}
