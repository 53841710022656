import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {


  transform(value: any): any {
    if(value == 1) {
      value = 'Lundi';
    } else if (value == 2) {
      value = 'Mardi';
    } else if (value == 3) {
      value = 'Mercredi';
    } else if (value == 4) {
      value = 'Jeudi';
    } else if (value == 5) {
      value = 'Vendredi';
    } else if (value == 6) {
      value = 'Samedi';
    } else {
      value = 'Dimanche';
    }
    return value;
  }

}
