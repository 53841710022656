import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {MinAgeInterval, ProposalService, RequestForEstimate, SuitableTime} from "@tatynanny/api";
import {ActivatedRoute, Router} from "@angular/router";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {

  proposal: any;
  year: any;
  parent: any;
  reqForEstimate: RequestForEstimate;
  timeList: SuitableTime[];

  detailId: any;

  // Costs :
  totalTTC: number = 0;
  CAF: number = 377.66;
  totalAfterCaf: number = 0;
  impot: number = 0;
  totalAfterImpot: number;
  totalNet: number = 0;

  loaded: boolean = false;

  title: string = '';

  @ViewChild('proposalDiv') proposalDiv;
  @ViewChild('proposalPage1') proposalPage1;
  @ViewChild('proposalPage2') proposalPage2;

  totalTime: number = 0;
  totalTimeMinutes: number = 0;

  totalDiff: number = 0;

  minutesWeek: number = 0;
  minutesMonth: any;

  hoursWeek: any;

  dateCreation: any;

  //productType : any;

  hour: any;
  minute: any;

  taux: any = 0;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private proposalService: ProposalService,

  ) { }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.detailId = this.activatedRoute.snapshot.paramMap.get('id');



    this.proposalService.loadUserWithProposalById(this.detailId).forEach(n => {
      this.parent = n.result.parent;
      this.proposal = n.result.proposal;

      this.hour = n.result.proposal.requestForEstimateLink.totalHoursPerMonth
      this.minute = (n.result.proposal.requestForEstimateLink.totalHoursPerMonth % 1);
      this.hour = this.hour - this.minute;
      this.minute = (n.result.proposal.requestForEstimateLink.totalHoursPerMonth % 1) * 60;


      console.log('HOUR:', this.hour, 'MINUTE', this.minute);


      let d = this.proposal.creationDate.split("-")[2];
      let m = this.proposal.creationDate.split("-")[1];
      let y = this.proposal.creationDate.split("-")[0];
      this.dateCreation = d + '-' + m + '-' + y;

      this.reqForEstimate = n.result.proposal.requestForEstimateLink;
      if (this.reqForEstimate.increaseChildren == true) {
        this.taux = this.taux + (this.reqForEstimate.percentChild != null ? this.reqForEstimate.percentChild : 0);
      }
      if (this.reqForEstimate.increaseLang == true) {
        this.taux = this.taux + (this.reqForEstimate.percentLang != null ? this.reqForEstimate.percentLang : 0);
      }


      this.title = this.reqForEstimate.pricing.productType;


      if (this.proposal.minAgeInterval != null) {
        if (this.proposal.minAgeInterval == MinAgeInterval.Less1 || this.proposal.minAgeInterval == MinAgeInterval.Between1To2) {
          this.CAF = 755.3;
        } else if (this.proposal.minAgeInterval == MinAgeInterval.Between3To5) {
          this.CAF = 377.66;
        } else {
          this.CAF = 0;
        }
      } else {
        if (this.parent.minAgeEnfants <= 3) {
          this.CAF = 755.3;
        }
        else if (this.parent.minAgeEnfants < 6) {
          this.CAF = 377.66;
        }
        else {
          this.CAF = 0;
        }
      }
      
      this.timeList = this.reqForEstimate.timeList;

      this.minutesWeek = (n.result.proposal.requestForEstimateLink.totalHoursPerWeek % 1) * 60;
      this.minutesMonth = (n.result.proposal.requestForEstimateLink.totalHoursPerMonth % 1) * 60;;

      this.hoursWeek = String(n.result.proposal.requestForEstimateLink.totalHoursPerWeek).split(".")[0];

      this.totalTTC = this.reqForEstimate.totalPriceTTC;

      if (this.title == 'EXTRACURRICULAR') {
        /*if(this.reqForEstimate.parent.prefLang === 'FRANCAIS' || this.reqForEstimate.parent.prefLang === null ) {
          if(this.parent.nbChildren >= 3) {
            this.totalTTC = this.reqForEstimate.totalPriceTTC * 1.1 ;
          } else {
            this.totalTTC = this.reqForEstimate.totalPriceTTC;
          }
        } else {
          if(this.parent.nbChildren >= 3) {
            this.totalTTC = this.reqForEstimate.totalPriceTTC * 1.21 ;
          }
          else {
            this.totalTTC = this.reqForEstimate.totalPriceTTC * 1.1;
          }
        }*/
        this.totalAfterCaf = this.totalTTC - this.CAF;
        if (this.totalAfterCaf <= 0) {
          this.CAF = parseFloat(((this.totalTTC * 85) / 100).toFixed(2));
          this.totalAfterCaf = parseFloat(((this.totalTTC * 15) / 100).toFixed(2));
          this.impot = this.totalAfterCaf / 2;
          this.totalAfterImpot = this.totalAfterCaf - this.impot;
        } else {
          this.impot = this.totalAfterCaf / 2;
          this.totalAfterImpot = this.totalAfterCaf - this.impot;
        }
      }
      else {
        this.totalTTC = this.reqForEstimate.totalPriceTTC;
        this.totalAfterCaf = 0;
        this.impot = this.totalTTC / 2;
        this.totalAfterImpot = this.impot;
      }

      this.totalNet = this.totalAfterImpot / this.reqForEstimate.totalHoursPerMonth;


      n.result.proposal.requestForEstimateLink.timeList.forEach(res => {
        this.totalDiff = res.diffTime + this.totalDiff;
      })
      this.totalTime = moment(this.totalDiff).hours() - 1;
      console.log(this.totalTime);
      this.totalTimeMinutes = moment(this.totalDiff).minutes();
      console.log(this.totalTime);

      this.loaded = true;
    })

  }

  msToTime(duration): string {
    var minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    var h = (hours < 10) ? "0" + hours : hours;
    var m = (minutes < 10) ? "0" + minutes : minutes;

    if (m == '00' && h == '00') {
      return '-';
    }
    else if (m == '00') {
      return h + "h";
    }
    else {
      return h + "h" + m;
    }
  }

  async generatePdf() {
    const imgWidth = 210;
    const pageHeight = 295;
    const position = 0;
    let imgHeight = 0;
    const doc = new jsPDF('p', 'mm');
    const image1 = await this.getImageData(this.proposalPage1.nativeElement);
    imgHeight = image1.height * imgWidth / image1.width;
    doc.addImage(image1.url, 'PNG', 0, position, imgWidth, imgHeight);
    await this.generateElement2pdf(this.proposalPage2.nativeElement, doc);
    doc.save('Devis.pdf');
  }

  async getImageData(element) {
    const canvas = await html2canvas(element);
    return {url: canvas.toDataURL("image/jpeg"), height: canvas.height, width: canvas.width}
  }

  async generateElement2pdf(element, doc: jsPDF) {
    const canvas = await this.getImageData(element)
    const image = canvas.url;
    const imgWidth = 210;
    const pageHeight = 295;
    let position = 0;
    let imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;
    doc.addPage();
    doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight - 5);
      heightLeft -= pageHeight;
    }
  }
}
