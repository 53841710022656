import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {RemoveCardForm} from "@tatynanny/api/model/removeCardForm";
import {CardInfo, PaymentResourceService, StripePaymentService} from "@tatynanny/api";
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {AddCardComponent} from '../add-card/add-card.component';
declare var Stripe: any;


@Component({
  selector: 'app-modal-payment-card',
  templateUrl: './modal-payment-card.component.html',
  styleUrls: ['./modal-payment-card.component.scss'],
})
export class ModalPaymentCardComponent implements OnInit {

  @Input("discount") discount: boolean;
  @Input("credit") credit: any;

  cardNumber: any;
  cardFound: boolean = false;
  cardList: CardInfo[] = [];
  card: CardInfo;

  stripe: any;
  elements: any;
  cardElement: any;
  priceCeil: any;

  remain: any;
  total: any;
  price: any;
  coupon: boolean = false;
  couponCode: string = "";

  amount: any;
  subTotal: any;

  codeValidated: boolean = false;
  reduction: any;
  totalAfterCode: any;


  constructor(
    private modalCtrl: ModalController,
    private paymentService: PaymentResourceService,
    private keycloakService: KeycloakIonicService,
    private alertService: AlertService,
    private stripePaymentService: StripePaymentService,
  ) {
    this.stripe = Stripe('pk_test_51LfL3bHycFurCKDkIOQHIhk9akhWHCIBvLGHoYwceSQcO7bakxTDwBTzQ6bFzqMuBdeKw1wvdnui5QQjgX2fYoI3001LokDF98');
  }

  addCoupon(): void {
    this.coupon = !this.coupon;
    if (this.coupon == false) {
      this.couponCode = "";
    }
  }

  ngOnInit(): void {
    // console.log("PRODUCT PAYMENT :", this.product);
    // console.log("DISCOUNT PAYMENT :", this.discount);

    // if (!this.buyCredit) {
    //   this.credit = this.credit.toFixed(2);

    //   this.price = (this.product.prices[0].unitAmount / 100).toFixed(2);
    //   if (this.credit > 0) {
    //     if (this.credit > this.product.prices[0].unitAmount / 100) {
    //       this.priceCeil = (this.product.prices[0].unitAmount / 100).toFixed(2);
    //       this.remain = (this.credit - this.priceCeil).toFixed(2);
    //       this.total = 0.00.toFixed(2);
    //     } else {
    //       this.priceCeil = this.credit;
    //       this.total = parseFloat((this.product.prices[0].unitAmount / 100).toFixed(0)) - this.credit;
    //       this.remain = 0;
    //     }
    //   } else {
    //     this.total = (this.product.prices[0].unitAmount / 100).toFixed(2);
    //   }
    // } else {
    //   this.subTotal = 0.00.toFixed(2);
    //   this.total = 0.00.toFixed(2);
    // }

    this.refresh();
  }

  onChangeAmount(): void {
    console.log(this.amount);
    if (this.amount >= 1) {
      this.subTotal = (this.amount * 1.2).toFixed(2);
      this.total = (this.amount * 1.2).toFixed(2);
    } else {
      this.subTotal = 0.00.toFixed(2);
      this.total = 0.00.toFixed(2);
    }
  }

  refresh(): void {

    this.keycloakService.isLoggedIn().then(connected => {
      if (connected) {
        this.paymentService.listCreditCards().subscribe(r => {
          console.log(r);
          if (r.items.length > 0) {
            this.cardFound = true;
            this.cardList = r.items;
          }
          for (let item of r.items) {
            if (item.defaultCard) {
              this.card = item;
            }
          }

        })
      }
    })
  }

  ngAfterViewInit(): void {
    this.elements = this.stripe.elements();
    this.cardElement = this.elements.create('card');
    this.cardElement.mount('#card-element');

    console.log("CARD ELEMENT: ", this.cardElement);

    this.cardElement.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  validateCredit(): void {
    this.alertService.ask("Paiement", "Voulez-vous valider le paiement ?", "Oui, valider", () => {
      this.alertService.loading().then(() => {
        if (this.cardList.length == 0 && !this.card) {
          this.stripe.createToken(this.cardElement).then(createToken => {
            if (createToken.error) {
              console.log('Error creating payment method.');
              const errorElement = document.getElementById('card-errors');
              errorElement.textContent = createToken.error.message;
              this.alertService.error("Une erreur est survenue lors de l'ajoute de votre carte, veuillez réessayez ultérieurement.");
            } else {
              console.log('Token acquired!', createToken.token, createToken.token.id);
              this.paymentService.addCreditCard({
                cardId: createToken.token.id,
                defaultCard: true,
              }).forEach(addCreditCard => {
                console.log("AddCreditCard", addCreditCard);
                this.paymentService.defaultCard(addCreditCard.result).subscribe(defaultCard => {
                  console.log("Default Card", defaultCard);
                  this.stripePaymentService.paymentIntent({
                    cardId: addCreditCard.result,
                    discount: false,
                    promotionCode: this.couponCode != "" ? this.couponCode : null,
                  }).subscribe(paymentIntent => {
                    console.log("Payment Intent", paymentIntent);
                    this.stripe.confirmCardPayment(paymentIntent.result).then(confirmCardPayment => {
                      console.log("Confirm card payment", confirmCardPayment);
                      if (confirmCardPayment.error) {
                        if (confirmCardPayment.error.paymentIntent.status != "succeeded") {
                          this.alertService.closeLoader();
                          this.alertService.error("Paiement échoué !");
                          this.modalCtrl.dismiss();
                        } else {
                          this.alertService.closeLoader();
                          this.alertService.success("Paiement réussi ! Cela peut prendre quelques instants ...");
                          this.modalCtrl.dismiss();
                        }
                      } else {
                        this.alertService.closeLoader();
                        this.alertService.success("Paiement réussi ! Cela peut prendre quelques instants ...");
                        this.modalCtrl.dismiss();
                      }
                    }).catch(error => {
                      console.log(error);
                    })
                  })
                })
              })
            }
          })
        } else {
          this.paymentService.defaultCard(this.card.id).subscribe(defaultCard => {
            console.log("Default Card", defaultCard);
            this.stripePaymentService.paymentIntent({
              cardId: this.card.id,
              promotionCode: this.couponCode != "" ? this.couponCode : null,
              credits: this.amount,
              discount: false,
            }).subscribe(paymentIntent => {
              console.log("Payment Intent", paymentIntent);
              this.stripe.confirmCardPayment(paymentIntent.result).then(confirmCardPayment => {
                console.log("Confirm card payment", confirmCardPayment);
                if (confirmCardPayment.error) {
                  if (confirmCardPayment.error.paymentIntent.status != "succeeded") {
                    this.alertService.closeLoader();
                    this.alertService.error("Paiement échoué");
                    this.modalCtrl.dismiss();
                  } else {
                    this.alertService.closeLoader();
                    this.alertService.success("Paiement réussi. Cela peut prendre quelques instants ...");
                    this.modalCtrl.dismiss();
                  }
                } else {
                  this.alertService.closeLoader();
                  this.alertService.success("Paiement réussi ! Cela peut prendre quelques instants ...");
                  this.modalCtrl.dismiss();
                }
              }).catch(error => {
                console.log(error);
              })
            })
          })
        }
      })
    }, "Non, annuler", () => {
      this.modalCtrl.dismiss("error");
    })
  }

  changeCard(cardSelected: CardInfo): void {
    this.card = cardSelected;
  }

  async openCardModal() {
    const modal = await this.modalCtrl.create({
      component: AddCardComponent,
    });
    modal.onDidDismiss().then(value => {
      console.log(value);
      if (value.data) {
        this.changeCard(value.data);
      }
    })
    return await modal.present();
  }

  dismissModal(): void {
    this.modalCtrl.dismiss();
  }

  // applyCode(): void {
  //   if (this.amount > 0) {
  //     this.stripePaymentService.applyCode(this.couponCode).subscribe(applyCode => {
  //       console.log("ApplyCode", applyCode);
  //       if (applyCode.status) {
  //         if (applyCode.result) {
  //           if (applyCode.result.amount > this.amount) {
  //             this.alertService.infos("Information", `Le montant de votre réduction est supérieur au montant de votre achat (Réduction de : ${applyCode.result.amount} €)`, "Compris");
  //           } else {
  //             this.reduction = applyCode.result.amount;
  //             this.alertService.loading("Validation du code ...").then(() => {
  //               this.totalAfterCode = this.total - applyCode.result.amount;
  //               this.codeValidated = true;
  //               this.alertService.closeLoader();
  //             })
  //           }
  //         }
  //       } else {
  //         this.alertService.infos("Information", "Le code promotionnelle est invalide.", "Compris");
  //       }
  //     })
  //   } else {
  //     this.alertService.infos("Information", "Veuillez d'abord saisir le montant souhaité", "Compris");
  //   }
  // }

}
