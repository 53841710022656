import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billStatus'
})
export class BillStatusPipe implements PipeTransform {

  transform(value: string): string {
    if(value == 'NEW') {
      value = "Nouveau";
    } else if(value == 'SENT') {
      value = "En cours";
    } else if(value == 'NOT_PAYED') {
      value = "Non payée";
    } else if(value == 'PAYED') {
      value = 'Payée';
    } else if(value == 'WAITING') {
      value = "En attente";
    } else if(value == 'SUSPENDED') {
      value = 'Suspendu';
    }
    return value;
  }


}
