import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountType, ApiResult, AuthProvider, MinAgeInterval, PhoneOtpForm, RegisterAccountForm, RegisterService, RequestForEstimate} from '@tatynanny/api';
import {Router} from '@angular/router';
import {IonInput, IonItem} from '@ionic/angular';
import {AlertService} from 'src/app/services/alert/alert.service';
import {EnvService} from 'src/app/services/environment/env.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {KeycloakSocialLoginService} from 'src/app/services/keycloak/keycloak-social-login.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  /**
   * Define if user phone is valid
   */
  @Output() formEventOutput: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  /**
   * Save phone value for register form
   */
  @Output() savePhoneNumber: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Boolean to show/hide form
   */
  isValidPhone: boolean = false;
  errorMessage: string = '';

  /**
   * User values
   */
  phoneNumber: string = '';
  codeNumber: string = '';
  phoneUser: PhoneOtpForm = new (class implements PhoneOtpForm {
    otp: string;
    phoneNumber: string;
  })();
  sendPhone: boolean = false;

  newAccount: RegisterAccountForm = new class implements RegisterAccountForm {
    authProvider?: AuthProvider;
    formattedAddress?: string;
    addressNumber?: string;
    address?: string;
    city?: string;
    zipcode?: string;
    country?: string;
    latitude: number;
    longitude?: number;
    nbChild?: number;
    minAgeInterval?: MinAgeInterval;
    requestForEstimate?: RequestForEstimate;
    accountType: AccountType & string;
    email: string = '';
    firstname: string = '';
    id: number;
    lastname: string = '';
    password: string = '';
    phoneNumber: string = '';
  }

  // pass: string = '';

  error: boolean = false
  tabError: string[] = [
    '', '', '', '', ''
  ]
  confirmPwd: string

  validateForm: boolean = false;

  selected: 'VOID' | 'APP' | 'GOOGLE' | 'FACEBOOK' = 'VOID';

  @ViewChild('lastname') lastnameInput;
  @ViewChild('firstname') firstnameInput;
  @ViewChild('email') emailInput;
  @ViewChild('password') passwordInput: IonInput;

  @ViewChild('passwordItem') passwordItem: ElementRef;

  pass: string = '';

  segment: 'parent' | 'babysitter' = "parent";


  constructor(
    private http: HttpClient,
    private alert: AlertService,
    private registerService: RegisterService,
    private router: Router,
    private keycloakService: KeycloakIonicService,
    private keycloakSocialLoginService: KeycloakSocialLoginService,
    private envService: EnvService,
  ) { }

  ngOnInit() { }


  setFocus(elementId): void {
    elementId.setFocus();
  }


  login(): void {
    this.keycloakService.login();
  }

  selectRegister(type: any): void {
    this.selected = type;
    this.keycloakSocialLoginService.redirectOauthPage(type);
  }

  askPhone(number: string): void {

    this.alert.loading();
    this.phoneUser.phoneNumber = number;
    let observable: Observable<ApiResult> = this.registerService.askPhoneOTP(this.phoneUser);
    const FILTER_PHONE: RegExp = /^[+]?\d{10,11}$/;
    if (FILTER_PHONE.test(this.phoneUser.phoneNumber)) {
      observable
        .forEach((r) => {
          if (r.status) {
            this.sendPhone = true;
            this.isValidPhone = true;
            this.alert.closeLoader();
          }
        }).catch((e) => {
          if (e.error == 'PhoneAlreadyExistException') {
            this.errorMessage = 'Votre numéro est déjà utilisé';
            this.alert.closeLoader();
          } else {

            this.alert.closeLoader();
            this.alert.error(`Page indisponible, nous faisons le maximum pour vous rétablir l'accès`);
          }
        });
    } else {
      this.errorMessage = "Votre numéro n'est pas valide";
      this.alert.closeLoader();
    }
  }


  /**
   * To show/hide form
   */
  showForm() {
    this.isValidPhone = !this.isValidPhone;
    console.log(this.isValidPhone);
    this.formEventOutput.emit(this.isValidPhone);
  }
  /**
   * To save output phone number
   */
  savePhone(): void {
    this.savePhoneNumber.emit(this.phoneNumber.trim());
  }

  validatePhone(code: string): void {
    this.phoneUser.otp = code;
    console.log('CODE : ', code);

    let observable: Observable<ApiResult> = this.registerService.validatePhoneOTP(this.phoneUser);

    observable
      .forEach((r) => {
        console.log(r);
        if (r.status) {
          this.isValidPhone = true;
          this.errorMessage = '';
          console.log('CODE VALIDE');
        } else {
          this.isValidPhone = true;
          console.log(this.isValidPhone);
          this.errorMessage = "Le code n'est pas valide";
        }
      })
      .catch((e) => {
        if (e.error == 'NotValidatedPhone') {
          this.errorMessage = "Le code n'est pas valide";
        } else
          this.alert.error("Page indisponible, nous faisons le maximum pour vous rétablir l'accès");
      });
  }

  addAccount(): void {
    // REGEX
    const FILTER_STRING = /(^[a-zéèêëôœîïûüàáâæç\s-]+$)/i
    const FILTER_MAIL: RegExp = /(^[\w.-]+@[\w.-]+\.[\w]{2,4}$)/
    this.error = false

    // Filter inputs values

    if (!FILTER_STRING.test(this.newAccount.lastname.trim())) {
      this.tabError[0] = 'Veuillez renseigner votre nom'
      this.error = true
    }
    else
      this.tabError[0] = ''

    if (!FILTER_STRING.test(this.newAccount.firstname.trim())) {
      this.tabError[1] = 'Veuillez renseigner votre prénom'
      this.error = true
    }
    else
      this.tabError[1] = ''

    if (!FILTER_MAIL.test(this.newAccount.email.trim())) {
      this.tabError[2] = 'Veuillez renseigner votre email'
      this.error = true
    }
    else
      this.tabError[2] = ''

    if (this.newAccount.password.length < 6) {
      this.tabError[3] = 'Minimum 6 caractères';
      this.error = true;
    } else {
      this.tabError[3] = '';
    }

    if (this.newAccount.password != this.pass) {
      this.tabError[4] = `Le mot de passe n'est pas identique`
      this.error = true
    } else {
      this.tabError[4] = '';
    }


    // If 'error' is empty, save user and redirect to keycloak interface
    if (!this.error) {
      this.newAccount.accountType = AccountType.Client;
      this.newAccount.phoneNumber = this.phoneUser.phoneNumber;
      this.registerService.register(this.newAccount).forEach(r => {
        this.router.navigateByUrl('/home/register/validate');
      }).catch(e => {
        if (e.error == 'EmailAlreadyExistException')
          this.alert.error('Email déjà utilisé')
        else
          this.alert.error(e.error);
      })
    }
  }

  keyPressNumbers(event) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (! /^[a-zA-Z0-9]+$/.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  redirectToBabysitter(): void {
    window.location.href = `${this.envService.frontBabysitterUrl}` + '/home/register';
  }
}
