import {HttpClient} from '@angular/common/http';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController, ToastController} from '@ionic/angular';
import {
  RequestForEstimate,
  SuitableTime,
  ProductType,
  FrontparentService,
  ParentNeedRecurrence,
  RfeService,
} from '@tatynanny/api';
import {ModalDayofweekComponent} from 'src/app/components/modal-dayofweek/modal-dayofweek.component';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';

@Component({
  selector: 'app-rfe',
  templateUrl: './rfe.page.html',
  styleUrls: ['./rfe.page.scss'],
})
export class RfePage implements OnInit {

  request: RequestForEstimate = {
    timeList: Array<SuitableTime>(),
    totalHoursPerWeek: 0,
    pricing: {
      productType: ProductType.Extracurricular,
    },
  };

  segment: 'EXTRACURRICULAR' | 'MAID' | 'EDUCATIONAL_SUPPORT' = 'EXTRACURRICULAR';

  estimate: RequestForEstimate = {
    budgetBeforeAid: 0.0,
    budgetAfterCaf: 0.0,
    cafHelp: 0.0,
    taxCredits: 0.0,
    realCost: 0.0,
    totalHoursPerWeekStr: "",
    totalHoursPerMonthStr: "",
    totalHoursPerMonth: 0.0,
    pricePerHour: 0.0,
    increaseChildren: false,
  }
  tabList = [];

  constructor(
    private parentService: FrontparentService,
    private modalCtrl: ModalController,
    public toastController: ToastController,
    private rfeService: RfeService,
  ) { }

  ngOnInit() {

  }


  onSegmentChange(event): void {
    this.segment = event.detail.value;
  }

  reset(): void {
    this.tabList = [];
    this.request = {
      timeList: Array<SuitableTime>(),
      totalHoursPerWeek: 0,
      totalHoursPerMonth: 0,
      totalPriceTTC: 0,
      totalPriceHT: 0,
      pricing: {
        productType: this.segment,
      },
    }
  }


  removeSuitableTime(item: SuitableTime, index: number): void {
    this.tabList.splice(index, 1);
    this.refreshEstimate();
  }

  validateNeeds(): void {

  }


  refreshEstimate(): void {

    let valid: boolean = true;
    this.request.timeList.forEach(r => {
      if(!r.startTime || !r.endTime) {
        valid = false;
      }
    })

    if(valid) {
      this.rfeService.getEstimateRfe(this.request).subscribe(res => {
        console.log('[rfe] (refreshEstimate)', res);
        this.estimate = res.result;
      }, (error) => {
        console.error(error);
      });
    }
  }


  async openModalDayOfWeek() {
    const modal = await this.modalCtrl.create({
      component: ModalDayofweekComponent,
      cssClass: 'modal-calendar',
    });
    modal.onDidDismiss().then(res => {
      if (res.data) {
        res.data.forEach(r => {
          this.tabList.push({
            dayOfWeek: r, startTime: '16:30', endTime: '19:30', frequency: ParentNeedRecurrence.Weekly, diffTime: 0,
          });
        })
        this.sortDays();
      }
    })
    await modal.present();
  }

  sortDays(): void {
    this.tabList.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
  }

}
