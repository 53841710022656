import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hour'
})
export class HourPipe implements PipeTransform {

  transform(value: any): any {
    let minutes = parseFloat((value % 1).toFixed(2));
    let hours = value - minutes;
    minutes = parseFloat((minutes * 60).toFixed(0));
    value = hours.toString() + 'H' + minutes.toString() + 'm';
    return value;
  }

}
