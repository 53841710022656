import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'civilite'
})
export class CivilitePipe implements PipeTransform {

  transform(value: any) : string {
    if(value == 1) {
      value = 'M.';
    } else if(value == 2) {
      value = 'Mme.'
    } else if(value == 3) {
      value = 'Mme.'
    }
    return value;
  }

}
