import {Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IonDatetime, AlertController, ModalController, PopoverController} from '@ionic/angular';
import * as moment from 'moment';
import {Account, Candidate, ContactUsForm, FrontgeneralService, Parent, TimeSchedule, TimeScheduleForm, TimeScheduleHistory, TimeScheduleMonth, TimescheduleService, TimesheetReportForm} from '@tatynanny/api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.page.html',
  styleUrls: ['./event.page.scss'],
})
export class EventPage implements OnInit {

  detailId: any;
  title: string = "";
  active: boolean = false;
  currentTimeScheduleMonth: TimeScheduleMonth = {
    parent: {
      account: {} as Account,
    } as Parent,
    candidate: {
      account: {} as Account,
    } as Candidate,
  } as TimeScheduleMonth;
  total: number = 0;
  times = [];
  modify: boolean = false;
  message: string = "";
  error: boolean = false;
  @ViewChild("page") page: ElementRef;

  displayedColumns = [
    "date",
    "startDate",
    "endDate",
    "diff",
    "action",
  ];

  form: TimesheetReportForm = {
    subject: "Erreur sur le relevé d'heure",
    email: '',
    message: '',
  };

  warned: boolean[] = [];
  modified: boolean[] = [];
  
  constructor(
    private keycloakIonicService: KeycloakIonicService,
    private alertCtrl: AlertController,
    @Inject(LOCALE_ID) private locale: string,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private frontTimeScheduleService: TimescheduleService,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private generalService: FrontgeneralService,
  ) { }

  onChangeStartTime(event: any): void {
    console.log(event);
  }

  onChangeEndTime(event: any): void {
    console.log(event);
  }


  ngOnInit(): void {
    this.refreshPage();
  }

  refreshPage(): void {
    this.alertService.loading();

    // this.currentMonth = this.calendar.currentDate.toLocaleString('default', {month: 'long'}).toUpperCase();

    this.detailId = this.activatedRoute.snapshot.paramMap.get('id');
    this.form.timeScheduleMonthId = this.detailId;

    this.keycloakIonicService.isLoggedIn().then(connected => {
      if (connected) {
        this.loadTimeSchedule();
        this.alertService.closeLoader();
      } else {
        this.alertService.closeLoader();
        //this.alertService.temp('Vous êtes déconnecté.', 2000);
        this.router.navigateByUrl('/home/tatynanny', {
          replaceUrl: true,
        });
      }
    });
  }

  // loadHistory() {
  //   this.frontTimeScheduleService.loadHistoryByTimeScheduleId(Number(this.detailId)).subscribe(t => {
  //     for (let item of t.items) {
  //       this.history.push(item);
  //       this.history.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
  //     }
  //     this.loadHistoryEvents();
  //   });
  // }

  loadTimeSchedule(): void {
    this.frontTimeScheduleService.loadTimeScheduleById(Number(this.detailId)).subscribe(r => {
      console.log(r);
      this.currentTimeScheduleMonth = r.result;
      this.loadTimes(r.result.timeSchedules);
      if (r.result.timeScheduleStatus != 'ACTIVE' && r.result.timeScheduleStatus != 'BILLED' && r.result.timeScheduleStatus != 'ARCHIVED') {
        this.active = false;
        this.title = r.result.parentContract.proposal.requestForEstimateLink.pricing.productType;
        // this.loadHistory();
        // this.loadEvents();
      } else {
        this.title = r.result.parentContract.proposal.requestForEstimateLink.pricing.productType;
        this.active = true;
      }

      this.form.email = r.result.parent.account.email;
    });
  }

  loadTimes(times: TimeSchedule[]): void {
    times.forEach(r => {
      let diff = r.diffTime / 3600000;
      console.log(diff);
      this.total = this.total + diff;
      if(moment(r.startDate).date() == moment(r.endDate).date()) {
        this.times.push({
          date: moment(r.startDate).locale('fr').format('dddd DD/MM'),
          startDate: moment(r.startDate),
          endDate: moment(r.endDate),
          diff: diff,
        })
      } else {
        this.times.push({
          date: moment(r.startDate).locale('fr').format('dddd DD/MM') + " au " + moment(r.endDate).format('dddd DD/MM'),
          startDate: moment(r.startDate),
          endDate: moment(r.endDate),
          diff: diff,
        })
      }
    });

  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  errorTime(): void {
    this.modify = true;
  }



  async generatePdf() {
    console.log(this.page);
    const imgWidth = 210;
    const pageHeight = 295;
    const position = 0;
    let imgHeight = 0;
    const doc = new jsPDF('p', 'mm');
    const image1 = await this.getImageData(this.page.nativeElement);

    imgHeight = image1.height * imgWidth / image1.width;
    doc.addImage(image1.url, 'PNG', 0, position, imgWidth, imgHeight);

    doc.save(`${this.currentTimeScheduleMonth.timeScheduleMonthCode}.pdf`);
  }

  async getImageData(element) {
    const canvas = await html2canvas(element);
    return {url: canvas.toDataURL("image/jpeg"), height: canvas.height, width: canvas.width}
  }

  addReportTime(time: any, index: number, deleted: boolean) {
    if(deleted) {
      this.form.message = this.form.message + "\n" + "Suppression: " + moment(time.startDate).locale('fr').format('dddd DD/MM HH:mm') + " - " + moment(time.endDate).locale('fr').format('dddd DD/MM HH:mm');
      this.warned[index] = true;
    } else {
      this.form.message = this.form.message + "\n" + "Heures saisies : " + moment(time.startDate).locale('fr').format('dddd DD/MM HH:mm') + " - " + moment(time.endDate).locale('fr').format('dddd DD/MM HH:mm') + "\nNouveau horaire : " + moment(time.startDate).locale('fr').format('dddd DD/MM') + " - __:__ - " + moment(time.endDate).locale('fr').format('dddd DD/MM') + " - __:__\n";
      this.modified[index] = true;
    }
  }


  sendReport(): void {
    this.alertService.ask('Information', `Voulez-vous envoyer votre signalement d'erreur sur votre relevé d'heure ?`, 'Oui, envoyer', () => {
      this.frontTimeScheduleService.report(this.form).subscribe(res => {
        if(res.status) {
          this.alertService.success('Notre équipe va examiner votre signalement et nous vous ferons un retour très rapidement.');
          this.error = false;
        }
      }, (error) => {
        this.alertService.error("Impossible d'envoyer votre signalement. Veuillez réessayez ultérieurement.");
      })
    });
  }
}
