import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EnvService} from "../environment/env.service";

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private httpClient: HttpClient, private envService : EnvService) {
  }

  public upload(userRole: string, fileType: any, file: any) {

    const formData = new FormData()

    formData.append('uploadedFile', file)
    const profileType = userRole == 'CLIENT' ? 'parent' : 'babysitter'

    const serverUrl = this.envService.apiUrl + '/api/v1/' + profileType.toLowerCase() + '/profile/upload-file/' + fileType;
    return this.httpClient.post<any>(serverUrl, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
