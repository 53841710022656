import { Pipe, PipeTransform } from '@angular/core';
import {MinAgeInterval} from '@tatynanny/api';

@Pipe({
  name: 'minAgeInterval'
})
export class MinAgeIntervalPipe implements PipeTransform {

  transform(value: any): string {
    if(value == MinAgeInterval.Less1) {
      value = "moins de 1 an";
    } else if(value == MinAgeInterval.Between1To2) {
      value = "1 à 2 ans";
    } else if(value == MinAgeInterval.Between3To5) {
      value = "3 à 5 ans";
    } else if(value == MinAgeInterval.More6) {
      value = "plus de 6 ans";
    } 

    return value;
  }

}
