import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-periscolaire',
  templateUrl: './periscolaire.component.html',
  styleUrls: ['./periscolaire.component.scss'],
})
export class PeriscolaireComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
