import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Bill, BillService, Pricing} from "@tatynanny/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-invoice-detail-ae',
  templateUrl: './invoice-detail-ae.page.html',
  styleUrls: ['./invoice-detail-ae.page.scss'],
})
export class InvoiceDetailAePage implements OnInit {


  detailId: string;
  title: string;
  currentFacture: Bill;
  currentFactId: any;

  currentTimeSched: any;
  babySitter: any;
  totalTTC: any;
  totalHours: any;
  minutes: any;
  thTTC = 0;

  candidateLastname: string = "";
  candidateFirstname: string = "";
  candidateAddress1: string = "";
  candidateAddress2: string = "";
  candidateAddress: string = "";
  candidateSiret: string = "";
  candidateSAP: string = "";

  parentLastname: string = "";
  parentFirstname: string = "";
  parentAddress1: string = "";
  parentAddress2: string = "";
  parentAddress: string = "";
  parentCivilite: any;

  startDate: string = "";
  endDate: string = "";
  designation: string = "";
  hoursCompleted: any = 0;
  totalPricePerHour: any = 0;
  service: Pricing;
  taux: number = 0;
  priceNanny: number = 0;

  tva: number = 0.1;
  tauxTva: string = "10%";
  tvaMultiple: number = 1.1;

  @ViewChild('invoiceDiv') invoiceDiv;
  @ViewChild('invoicePage1') invoicePage1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private invoiceService: BillService,

  ) { }

  ngOnInit() {
    this.detailId = this.activatedRoute.snapshot.paramMap.get('id');
    this.invoiceService.loadBillById(Number(this.detailId)).forEach(r => {
      console.log('BILL LOADED :', r.result);
      this.currentFacture = r.result;
      this.babySitter = r.result.timeScheduleMonth.candidate;
      this.currentFactId = r.result.id;
      this.currentTimeSched = r.result.timeScheduleMonth;
      this.title = r.result.timeScheduleMonth.parentContract.proposal.requestForEstimateLink.pricing.productType;
      this.totalHours = r.result.timeScheduleMonth.hoursCompleted;


      this.candidateLastname = this.currentFacture.timeScheduleMonth.candidate.account.lastname;
      this.candidateFirstname = this.currentFacture.timeScheduleMonth.candidate.account.firstname;
      this.candidateAddress1 = this.currentFacture.timeScheduleMonth.candidate.addressNumber + ' ' + this.currentFacture.timeScheduleMonth.candidate.address;
      this.candidateAddress2 = this.currentFacture.timeScheduleMonth.candidate.zipcode + ' ' + this.currentFacture.timeScheduleMonth.candidate.city + ', ' + this.currentFacture.timeScheduleMonth.candidate.country;
      this.candidateAddress = this.currentFacture.timeScheduleMonth.candidate.formattedAddress;
      this.candidateSiret = this.currentFacture.timeScheduleMonth.candidate.numSiret;
      this.candidateSAP = this.currentFacture.timeScheduleMonth.candidate.numAgrement;
  
      this.parentLastname = this.currentFacture.timeScheduleMonth.parent.account.lastname;
      this.parentFirstname = this.currentFacture.timeScheduleMonth.parent.account.firstname;
      this.parentAddress1 = this.currentFacture.timeScheduleMonth.parent.addressNumber + ' ' + this.currentFacture.timeScheduleMonth.parent.address;
      this.parentAddress2 = this.currentFacture.timeScheduleMonth.parent.zipcode + ' ' + this.currentFacture.timeScheduleMonth.parent.city + ', ' + this.currentFacture.timeScheduleMonth.parent.country;
      this.parentCivilite = this.currentFacture.timeScheduleMonth.parent.civilite;
      this.parentAddress = this.currentFacture.timeScheduleMonth.parent.formattedAddress;
  
      this.startDate = this.currentFacture.startDate != null ? this.currentFacture.startDate : this.currentFacture.timeScheduleMonth.startDate;
      this.endDate = this.currentFacture.endDate != null ? this.currentFacture.endDate : this.currentFacture.timeScheduleMonth.endDate;
      this.hoursCompleted = this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted;
      this.totalPricePerHour = this.currentFacture.totalPricePerHour.toFixed(2);
      this.priceNanny = this.currentFacture.priceNanny != null ? this.currentFacture.priceNanny : this.currentFacture.timeScheduleMonth.priceNanny;
      this.service = this.currentFacture.timeScheduleMonth.parentContract.contractNanny.pricing;


      if(this.service.productType == 'MAID') {
        if(this.service.pricingType == 'PROFESSIONAL') {
          this.tva = 0.2;
          this.tvaMultiple = 1.2;
          this.tauxTva = "20%";
          this.designation = "Entretien de ménage";
        } else {
          this.designation = "Entretien de ménage à domicile"
        }
      } else if(this.service.productType == 'EXTRACURRICULAR') {
        this.tva = 0.1;
        this.tvaMultiple = 1.1;
        this.tauxTva = "10%";
        this.designation = "Garde d'enfants à domicile"
      } else if(this.service.productType == 'EDUCATIONAL_SUPPORT') {
        this.tva = 0.1;
        this.tvaMultiple = 1.1;
        this.tauxTva = "10%";
        this.designation = "Soutien scolaire à domicile"
      } else if(this.service.productType == 'BABYSITTING') {
        this.tva = 0.2;
        this.tvaMultiple = 1.2;
        this.tauxTva = "20%";
        this.designation = "Garde occasionnelle"
      }


      if(this.currentFacture.timeScheduleMonth.subContract.increaseChildren == true) {
        this.taux = this.taux + (this.currentFacture.timeScheduleMonth.subContract.percentChild != null ? this.currentFacture.timeScheduleMonth.subContract.percentChild : 0);
      }
      if(this.currentFacture.timeScheduleMonth.subContract.increaseLang == true) {
        this.taux = this.taux + (this.currentFacture.timeScheduleMonth.subContract.percentLang != null ? this.currentFacture.timeScheduleMonth.subContract.percentLang : 0);
      }

      if(this.taux != 0) {
        this.totalTTC = (this.currentFacture.totalPricePerHour * ((this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted)) + ((this.currentFacture.totalPricePerHour * (this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted)) * this.taux) / 100);
      } else {
        this.totalTTC = (this.currentFacture.totalPricePerHour * ((this.currentFacture.hoursCompleted != null ? this.currentFacture.hoursCompleted : this.currentFacture.timeScheduleMonth.hoursCompleted)));
      }


      if(this.isFloat(this.totalHours)) {
        this.minutes = ((this.totalHours % 1) * 60).toFixed(0);
        this.totalHours = this.totalHours - (this.totalHours % 1);
      }
    });
  }

  isFloat(n){
    return Number(n) === n && n % 1 !== 0;
  }

  async generatePdf(){
    const imgWidth = 210;
    const pageHeight = 295;
    const position = 0;
    let imgHeight = 0;
    const doc = new jsPDF('p', 'mm');
    const image1 = await this.getImageData(this.invoiceDiv.nativeElement);
    imgHeight = image1.height * imgWidth / image1.width;
    doc.addImage(image1.url, 'PNG', 0, position, imgWidth, imgHeight);
    doc.save('Facture-'+this.currentFacture.billIdAe+'.pdf');
  }

  async getImageData(element){
    const canvas = await html2canvas(element);
    return {url: canvas.toDataURL("image/jpeg"), height: canvas.height, width: canvas.width}
  }
}
