import {HttpClient} from '@angular/common/http';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {SecurityService} from '@tatynanny/api';
import {AlertService} from './services/alert/alert.service';
import {EnvService} from './services/environment/env.service';
import {KeycloakIonicService} from './services/keycloak/keycloak-ionic.service';
import {SharedService} from './services/shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  provider: string = '';
  session: string = '';
  code: string = '';



  constructor(
    private platform: Platform,
    private keycloakService: KeycloakIonicService,
    private router: Router,
    private securityService: SecurityService,
    private alertService: AlertService,
    private envService: EnvService,
    private sharedService: SharedService,
  ) {
    this.initializeApp();
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      console.log('InitializeApp');
    })
  }
}
