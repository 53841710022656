import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Preferences} from '@capacitor/preferences';
import {IonContent, IonPopover, Platform, ModalController} from '@ionic/angular';
import {SecurityService} from '@tatynanny/api';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {NewsletterComponent} from 'src/app/components/newsletter/newsletter.component';
import {AlertService} from 'src/app/services/alert/alert.service';
import {EnvService} from 'src/app/services/environment/env.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {SharedService} from 'src/app/services/shared/shared.service';
import {SwiperOptions} from 'swiper';

@Component({
  selector: 'app-tatynanny',
  templateUrl: './tatynanny.component.html',
  styleUrls: ['./tatynanny.component.scss'],
})
export class TatynannyComponent implements OnInit {

  pageName: string = '';
  selectedPrestation: 'periscolaire' | 'occasionnelle' | 'ludique' | 'tutorat' = 'periscolaire';
  imagePrestation: string = './assets/images/static/periscolaire.jpg';
  textPrestation: string = `Nos nannies de talents veillent avec douceur et bienveillance sur vos enfants dès
  la sortie de l'école : parc, goûter, devoirs, jeux, bain et dîner.
  C'est avec un professionnalisme sans faille qu'elles
  s'occupent de tout en attendant votre retour à la maison.`;
  mobilePlatform: boolean = false;
  @ViewChild('content') content: IonContent;
  row: any;
  rect: any;
  triggerAnimation: boolean = false;
  innerWidth: number = 0;
  config: SwiperOptions;
  provider: string = '';
  session: string = '';
  code: string = '';
  token: Subscription;
  connected: boolean = false;
  currentScroll: 'AGENCY' | 'HISTORY' | 'SERVICE' = 'AGENCY';

  @ViewChild('popover') popover: IonPopover;

  constructor(
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
    private router: Router,
    private keycloakService: KeycloakIonicService,
    private envService: EnvService,
    private alertService: AlertService,
    private securityService: SecurityService,
    private modalCtrl: ModalController,
    private sharedService: SharedService,
  ) {
    this.config = {
      slidesPerView: 3,
      spaceBetween: 0,
      loop: false,
    };
  }

  ngOnInit() {

    this.innerWidth = window.innerWidth;
    if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('mobileweb')) {
      this.mobilePlatform = false;
    } else if(this.platform.platforms().includes('mobileweb') && !this.platform.platforms().includes('mobile')) {
      this.mobilePlatform = true;
    }
    let kc_idp_hint = this.activatedRoute.snapshot.queryParamMap.get('kc_idp_hint')
    if (this.activatedRoute.snapshot.queryParamMap.get('kc_idp_hint')) {
      this.keycloakService.socialLogin({
        redirectUri: `${this.envService.keycloak.redirectUri}/register-social?kc_idp_hint=${kc_idp_hint}`,
      })
    }
    this.checkNewsletter();
  }


  checkNewsletter(): void {
    Preferences.get({key: 'newsletter'}).then(e => {
      console.log('Preferences', e.value);
      let element = <any>e.value;
      let json = JSON.parse(element);
      if (json == null) {
        this.openModalNews();
      } else if (json == true) {
        console.log('already subscribed to newsletter');
      } else if (typeof (json) == 'object') {
        let current = moment();
        let compare = moment(element.date).add(1, 'month');
        if (current.isAfter(compare)) {
          console.log('remove newsletter');
          Preferences.remove({
            key: 'newsletter',
          }).then(() => {
            this.openModalNews();
          })
        }
      }
    })
  }

  ngAfterViewInit() {
    this.row = document.querySelector('#row');
  }

  navigateToProfil(): void {
    if (this.keycloakService.getKeycloakInstance().authenticated) {
      this.router.navigateByUrl('/mon-espace/profil');
    } else {
      this.router.navigateByUrl('/home/demande-de-devis');
    }
  }

  navigateHome(): void {
    this.router.navigateByUrl('/home/tatynanny', {
      replaceUrl: true,
    });
  }

  selectPrestation(form) {
    this.selectedPrestation = form;
  }

  segmentChanged(ev) {
    console.log(ev);
    this.selectedPrestation = ev.detail.value;

    if (this.selectedPrestation == 'periscolaire') {
      this.imagePrestation = './assets/images/static/periscolaire.jpg';
      this.textPrestation = `
      Nos nannies de talents veillent avec douceur et bienveillance sur vos enfants dès la sortie de l'école : parc, goûter, devoirs, jeux, bain et dîner. C'est avec un professionnalisme sans faille qu'elles s'occupent de tout en attendant votre retour à la maison.`;
    } else if (this.selectedPrestation == 'occasionnelle') {
      this.imagePrestation = './assets/images/static/occasionnel.jpg';
      this.textPrestation = `Un imprévu, une envie de sortie de dernière minute. Faites appel à nos Nannies, disponibles en temps réel. TatyNanny, 1ère agence avec des profils sélectionnés, validés sur un entretien. Nos prestations sont déclarées, assurées et éligible au crédit d'impôt (-50%).`;
    } else if (this.selectedPrestation == 'ludique') {
      this.imagePrestation = './assets/images/static/ludique.jpg';
      this.textPrestation = `Donner vie à la créativité, stimuler la curiosité de vos enfants est un des piliers de TatyNanny. Des gardes créatives et ludiques sont proposées tout au long de l'année afin de faire découvrir à vos enfants des activités stimulantes.`;
    } else {
      this.imagePrestation = './assets/images/static/school.jpg';
      this.textPrestation = `Des cours particuliers personnalisés et adaptés au niveau scolaire de votre enfant sont dispensés par nos Nannies étudiants (e) issus(e) des meilleures écoles ou par nos nannies professeurs. Un programme de coaching individualisé est également mis en place pour être au plus proche de votre enfant et de révéler ainsi son meilleur potentiel. Que se soit pour l'année scolaire ou occasionnellement, pour une matière ou plusieurs. Vous pouvez compter sur le professionnalisme de nos Nannies.`;
    }
  }


  async openModalNews() {
    const modal = await this.modalCtrl.create({
      component: NewsletterComponent,
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'newsletter',
    })
    modal.present();
    modal.onDidDismiss().then(data => {
      console.log(data);
      if (data.data != null) {
        let object = {
          value: 'true',
          date: moment(),
        }
        Preferences.set({
          key: 'newsletter',
          value: data.data ? 'true' : JSON.stringify(object),
        }).then(() => {
          console.log('Newsletter key set')
        }, (error) => {
          console.log('Newsletter key not set', error);
        })
        if (data.data) {
          this.alertService.success('Souscription validée !');
        }
      }
    })
  }
  
}
