import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ParentNeedRecurrence } from '@tatynanny/api';

@Component({
  selector: 'app-modal-dayofweek',
  templateUrl: './modal-dayofweek.component.html',
  styleUrls: ['./modal-dayofweek.component.scss'],
})
export class ModalDayofweekComponent implements OnInit {

  days = [
    {day: 1, checked: false},
    {day: 2, checked: false},
    {day: 3, checked: false},
    {day: 4, checked: false},
    {day: 5, checked: false},
    {day: 6, checked: false},
    {day: 7, checked: false},
  ]

  selectedDays = [];

  form: 'day' | 'time' = 'day';

  rfe: [
    { dayOfWeek: number, startTime: string, endTime: string, frequency: ParentNeedRecurrence},
  ]

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  selectAll(): void {
    this.days.forEach(r => {
      r.checked = true;
    })
  }

  onWillDismiss(ev): void {
    console.log(ev);
  }

  dismiss(): void {
    this.days.forEach(r => {
      if(r.checked) {
        this.selectedDays.push(r.day);
      }
    })
    this.modalCtrl.dismiss(this.selectedDays);
  }

  dismissWithNothing(): void {
    this.modalCtrl.dismiss();
  }


}
