import {Component, OnInit} from '@angular/core';
import {ContactUsForm, FrontgeneralService} from '@tatynanny/api';
import {AlertService} from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  tabError: string[] = ['', '', ''];
  errorUserForm: boolean = false;

  form: ContactUsForm = {
    subject: '',
    email: '',
    message: '',
  };

  firstname: string = '';
  lastname: string = '';

  constructor(
    private frontgeneral: FrontgeneralService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {

  }

  checkcontactForm() {
    this.errorUserForm = false;
    if (this.form.email.length == 0) {
      this.tabError[0] = 'Veuillez renseigner votre adresse mail';
      this.errorUserForm = true;
    }
    if (this.form.subject.length == 0) {
      this.tabError[1] = 'Veuillez renseigner l\'Objet de votre mail';
      this.errorUserForm = true;
    }
    if (this.form.message.length == 0) {
      this.tabError[2] = 'Veuillez renseigner votre message';
      this.errorUserForm = true;
    }
    return this.errorUserForm;
  }

  send(): void {
    console.log(this.errorUserForm);
    console.log(this.tabError);
    if (!this.checkcontactForm()) {
      this.alertService.ask('Information', 'Voulez-vous envoyez votre message ?', 'Oui, envoyer', () => {
        this.frontgeneral.apiV1GeneralContactPost(this.form).forEach(r => {
          if(r.status) {
            this.form.subject = '';
            this.form.email = '';
            this.form.message = '';
            this.alertService.success('Message envoyé');
            
          }
        }).catch((e) => {
          console.log(e);
          this.alertService.error("Impossible d'envoyer votre message. Veuillez réessayez ultérieurement.");
        });
      })
    }
  }

}
