import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {


  bref: string = `
    TatyNanny est une agence de garde d’enfants haut de gamme située à Paris.<br>
    Notre agence propose une gamme de services étoffée, qui comprend notamment :<br>
    1. Les gardes d’enfants en périscolaire<br>
    2. Les gardes ponctuelles<br>
    3. Les gardes ludo-créatives<br>
    4. Les cours de soutien scolaire<br>
    5. Les ateliers d’accompagnement à la parentalité<br><br>

    Notre agence de babysitting se situe au 2 Rue Quinault – 75015 Paris, vous pouvez du Lundi au Vendredi venir à notre rencontre. Nous mettons également à disposition de nos membres une plateforme (web et application) simple et intuitive vous permettant de réserver une babysitter à la dernière minute…à proximité et surtout disponible en temps réel.
    <br><br>
    Que ce soit via l’agence ou via notre application (pour les babysitting de dernière minute), toutes nos babysitters suivent un processus de recrutement exigeant. Aucun.e de nos babysitters, salarié.e ou indépendant.e ne vous sera proposé.e sans que toutes les étapes de recrutements et de vérifications n’aient été validées minutieusement par nos équipes. Les parents peuvent désormais s’appuyer sur un service de garde d’enfants éligible à la CAF et au crédit d’impôt, permettant une mise en relation avec des babysitters fiables, déclaré.e.s, assuré.e.s et ce, même à la dernière minute.
  `

  babysitter = [
    {id: 0, question: "Comment s'inscrire sur TatyNanny ?", answer: `L’inscription sur TatyNanny est simple, rapide et 100% gratuite. Il suffit de vous rendre sur notre page d’accueil www.tatynanny.com et cliquer sur le bouton « Inscription » et sélectionner le profil « Je suis baby-sitter ». Après avoir complété les informations obligatoires, une validation de votre numéro de téléphone et de votre adresse email seront nécessaires pour accéder à l’étape suivante. Afin de permettre à nos équipes de vous connaître et de valider votre profil, renseignez toutes les informations demandées puis, confirmez votre inscription.
    <br><br>
    Afin de permettre à nos équipes de vous connaître et de vous contacter pour un entretien. Renseignez toutes les informations demandées puis confirmer votre inscription. `},
    {id: 1, question: "Comment se passe le processus de sélection/recrutement ?", answer: `Afin de garantir le respect de nos valeurs, la validation de votre profil est soumise dans un premier temps à un entretien téléphonique de « présélection » puis dans un deuxième temps à un entretien individuel dans nos locaux.
    <br><br>
    La pré-sélection :
    Après la confirmation de votre inscription, TatyNanny procède à l’examen de votre dossier. Un membre de notre équipe vous contacte dans les 48h pour un premier échange téléphonique, un entretien sera programmé si votre profil répond aux critères d’exigences recherchés par TatyNanny.
    <br><br>
    
    Entretien individuel :
    L’objectif de l’entretien est vous connaître (parcours, motivations, aptitude et engagements) et de partager avec vous les valeurs fondatrices de notre société. Nous sommes conscients que cette étape est primordiale pour notre future collaboration, c’est pourquoi notre équipe attache une importance particulière à ces échanges. Chez TatyNanny les Baby-sitters sont accompagnées à chaque stade de leur évolution. Etablir une relation solidaire et de confiance avec nos Babysitters est une de nos priorités.`},
  ];

  parents = [
    [
    {id: 0, question: "Comment s'inscrire sur TatyNanny ?", 
    answer: `
    L’inscription sur TatyNanny est simple, rapide et 100% gratuite. Il suffit de vous rendre sur notre page d’accueil http ://tatynanny.com/, cliquer sur le bouton « Inscription » et sélectionner le profil « Je suis Parent ». Complétez les informations obligatoires, une validation de votre numéro de téléphone et de votre adresse email est nécessaire pour accéder à l’étape suivante.
    <br><br>
    Après avoir complété les informations obligatoires, une validation de votre numéro de téléphone et de votre adresse email seront nécessaires pour accéder à l’étape suivante. Afin de permettre à nos équipes de vous connaître et de valider votre profil, renseignez toutes les informations demandées puis, confirmez votre inscription.`},
    {id: 1, question: "Comment se passe la validation de mon profil ?", 
    answer: `Une fois l’inscription terminée, un membre de l’équipe TatyNanny procède à la vérification de votre profil et des informations fournies par vos soins. Pour toutes demande, exceptée pour la garde occasionnelle, vous serez rapidement contacté.e afin de procéder à la qualification de vos besoins. Notre objectif est de vous fournir un service en parfaite adéquation avec vos besoins`},
    {id: 2, question: "Une fois membre, dois-je payer un abonnement ? ",
    answer: `Aucun frais d’abonnement n’est demandé pour utiliser nos services. Nous facturons uniquement les prestations réellement consommées.`}
    ],
    [
    {id: 3, question: "Comment trouver ma babysitter ?", 
    answer: `Pour les demandes de babysitting en périscolaire et pour les demandes de soutien scolaire, TatyNanny travaille sur mesure avec un accompagnement personnalisé. Notre équipe se donne pour mission de trouver la baby-sitter correspondant aux critères et aux besoins de la famille. Une fois votre inscription terminée, un membre de l’équipe vous contacte afin de cibler vos critères de recherche. Après la phase de qualification de vos besoins, nous vous présenterons un ou plusieurs dossiers complets de Nannies. Après avoir validé la ou les Nannies qui vous plaisent, un ou plusieurs entretiens seront réalisés afin de trouver la Nanny idéale. Après l’entretien nous attendons votre validation et celle de la Nannie pour mettre en place l’ensemble des procédures administratives pour que votre Nanny puisse commencer sa mission à temps. Vous n’avez rien à faire, Tatynanny s’occupe de tout.`},
    {id: 4, question: "Puis-je choisir ma babysitter ?", 
    answer: `Oui, vous aurez accès aux profils des baby-sitters. Le descriptif de son parcours, de ses expériences, de ses diplômes. Après l’entretien, vous faites votre choix et la babysitter aussi, si c’est un OUI des deux côtés alors elle devient votre babysitter !`},
    {id: 5, question: "Comment se déroule la mission au cours de l'année scolaire ?", 
    answer: `
    La nanny retenue à l’issue de l’entretien intervient chez vous et s’engage à respecter l’ensemble de vos pré-requis. Un relevé d’heures d’intervention est aussitôt mis en place, il est accessible directement depuis votre espace. Une facture au temps réel vous sera adressée à la fin du mois pour l’ensemble des heures effectuées par votre babysitter.
    <br><br>
    Pour les familles éligibles à la CAF, nous nous occupons de l’ensemble des démarches auprès de l’organisme.
    <br><br>
    Notre équipe vous contacte à la fin de chaque mois afin d’évaluer votre satisfaction quant aux prestations de votre Nanny. Vous pouvez bien évidemment contacter votre consultant attitré au cours de mois en cas de problème. Dans le cadre de notre programme d’accompagnement personnalisé, un consultant chargée de votre dossier et du suivi de votre nanny vous sera attitré pour tout au long de la mission, vous pouvez le contacter à votre convenance.`},
    {id: 6, question: "Comment régler la Nanny ?", 
    answer: `Pour les prestations en périscolaire, les règlements s’effectuent par prélèvement bancaire.`},
    ],
    [
    {id: 7, question: "Comment trouver ma babysitter de dernière minute ?", 
    answer: `Il suffit de vous connecter à l’application, de rentrer vos critères de recherches et notre algorithme se charge de vous présenter l’ensemble des Nannies disponibles et correspondant à vos besoins. Toutes nos prestations de babysitting sont déclarées, assurées et éligibles au crédit d’impôt.`},
    {id: 8, question: "Puis-je choisir ma babysitter ?", 
    answer: `Oui, vous aurez accès aux profils des Nannies : le descriptif de leur parcours, de leurs expériences, de leurs diplômes ainsi que les commentaires laissés par les autres parents Cela, en plus de notre rigoureux processus de sélection vous permettra de trouver votre Nannie idéal.`},
    {id: 9, question: "Comment se déroule la mise en contact, une fois la babysitter trouvée ?", 
    answer: `La mise en relation pour des demandes de garde ponctuelle est très simple. Elle est effective une fois la mission acceptée par les parties (parents et Nannie). Une notification de confirmation contenant les coordonnées de la babysitter vous sera envoyée. Vous pouvez dès lors, si vous le souhaitez, contacter votre babysitter.`},
    {id: 10, question: "En combien de temps après l’envoi de la demande de garde reçois-je la confirmation ?", 
    answer: `Les Nannies occasionnelles ont un délai de 30 minutes pour accepter ou décliner votre demande. En cas d’acceptation, TatyNanny vous adresse les coordonnées de la babysitter choisie par SMS ou notification. En cas de refus, TatyNanny vous informe par SMS, tout en prenant soin de vous suggérer une liste de babysitters disponibles de confiance.`},
    {id: 11, question: "Puis-je annuler une mission confirmée à la dernière minute ?", 
    answer: `L’annulation d’une mission est possible. Toutefois, pour les annulations des missions ponctuelles, les parents restent redevables de la 1re heure si l’annulation intervient 2 heures avant l’heure prévue d’intervention.`},
    {id: 12, question: "Que se passe-t-il en cas d’annulation par la baby-sitter ?", 
    answer: `Les Nannies ont la possibilité d’annuler une mission confirmée. Elles ont l’obligation de prévenir les parents ainsi que les membres de l’équipe de TatyNanny dans les plus brefs délais, ceci afin que nos équipes puissent proposer une Nannie de remplacement en urgence. Cependant, et dans la mesure où les baby-sitters sont suivis par nos équipes, nous les rencontrons mensuellement pour un bilan d’activité. Elles sont engagées vis-à-vis des familles par notre charte de qualité qui exclut les annulations répétitives. Aussi et dans un esprit d’exigence, notre règlement stipule que la Société se réserve le droit de cesser toute collaboration avec les Nannies ayant plus de 5 annulations dans le mois.`},
    {id: 13, question: "Dois-je rémunérer le baby-sitter pour les heures réservées mais non consommées ?", 
    answer: `Le nombre d’heures indiquées lors de votre réservation peut varier. Afin de rémunérer les baby-sitters au plus juste, l’application TatyNanny comporte un « Chronomètre de mission ». Les baby-sitters sont invitées, en présence des parents, à l’activer en début de mission et à l’arrêter lorsque celle-ci se termine. Notre système de facturation se chargera de ré-ajuster le montant de la mission au temps passé. Par conséquent, les parents paient uniquement les heures réellement consommées.`},
    {id: 14, question: "Comment signaler un comportement irrespectueux de la part d’un baby-sitter ?", 
    answer: `A la fin de chaque mission, les parents doivent noter et commenter le déroulement de la mission. La notation et les commentaires sont des retours d’expérience précieux pour la communauté des parents d’une part, et d’autre part ils constituent un excellent moyen de signaler à notre équipe les comportements irrespectueux de la part d’une de nos Nannies. En cas de non-respect de la charte de bonne conduite, nos équipes interviendront auprès des babysitters pour leur rappeler les valeurs fondatrices de notre communauté. Enfin, il est à rappeler qu’une Nannie peut être suspendue ou exclue de la communauté en cas de retours d’expérience négatifs et répétitifs. Le respect mutuel est un pilier fondamental de notre communauté, nous veillerons à ce qu’il soit appliqué pour le bien-être de tous nos membres.`},
    {id: 15, question: "Comment régler la babysitter et mon paiement est–il sécurisé ?", 
    answer: `Le règlement des missions s’effectue via notre plateforme par carte bancaire. Le paiement par carte bancaire est entièrement sécurisé. Il répond aux normes les plus strictes en terme de cryptage et de sécurisation des échanges. TatyNanny n'a pas accès à vos coordonnées bancaires, elles sont directement collectées par notre partenaire de paiement Stripe qui dispose de l'agrément européen.`},
    {id: 16, question: "Comment obtenir ma facture ?", 
    answer: `A la fin de chaque mission, TatyNanny met à votre disposition la facture correspondante aux prestations fournies. Vous pouvez la retrouver à la rubrique « Facture et paiement » accessible depuis votre espace personnel.`},
    ],
  ]
  
  constructor() { }

  ngOnInit() {}

}
