import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'month'
})
export class MonthPipe implements PipeTransform {

  transform(value: any): string {
    if(value == 1) {
      value = 'JANVIER'
    } else if (value == 2) {
      value = 'FEVRIER'
    } else if (value == 3) {
      value = 'MARS'
    } else if (value == 4) {
      value = 'AVRIL'
    } else if (value == 5) {
      value = 'MAI'
    } else if (value == 6) {
      value = 'JUIN'
    } else if (value == 7) {
      value = 'JUILLET'
    } else if (value == 8) {
      value = 'AOÛT'
    } else if (value == 9) {
      value = 'SEPTEMBRE'
    } else if (value == 10) {
      value = 'OCTOBRE'
    } else if (value == 11) {
      value = 'NOVEMBRE'
    } else if (value == 12) {
      value = 'DECEMBRE'
    }

    return value;
  }

}
