import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FrontparentService, SubContract} from '@tatynanny/api';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-detail-contrat-maid',
  templateUrl: './detail-contrat-maid.page.html',
  styleUrls: ['./detail-contrat-maid.page.scss'],
})
export class DetailContratMaidPage implements OnInit {

  detailId: any;
  subContract: SubContract;

  taux: number = 0;

  @ViewChild("contract") contract;
  @ViewChild("contract1") contract1;
  @ViewChild("contract2") contract2;
  @ViewChild("contract3") contract3;
  @ViewChild("contract4") contract4;

  constructor(
    private activatedRoute: ActivatedRoute,
    private parentService: FrontparentService,
  ) { }

  ngOnInit() {

    this.detailId = this.activatedRoute.snapshot.paramMap.get('id');

    this.parentService.loadSubContractById(this.detailId).forEach(r => {
      this.subContract = r;

      if (this.subContract.increaseChildren == true) {
        this.taux = this.taux + (this.subContract.percentChild != null ? this.subContract.percentChild : 0);
      }
      if (this.subContract.increaseLang == true) {
        this.taux = this.taux + (this.subContract.percentLang != null ? this.subContract.percentLang : 0);
      }
    })
  }

  async generatePdf() {
    const imgWidth = 210;
    const pageHeight = 295;
    const position = 0;
    let imgHeight = 0;
    const doc = new jsPDF('p', 'mm');
    const image1 = await this.getImageData(this.contract.nativeElement);
    imgHeight = image1.height * imgWidth / image1.width;
    doc.addImage(image1.url, 'PNG', 0, position, imgWidth, imgHeight);
    await this.generateElement2pdf(this.contract1.nativeElement, doc);
    await this.generateElement2pdf(this.contract2.nativeElement, doc);
    await this.generateElement2pdf(this.contract3.nativeElement, doc);
    if(this.contract4) {
      await this.generateElement2pdf(this.contract4.nativeElement, doc);
    }

    doc.save('Contrat-' + this.subContract.contractId + '.pdf');
  }

  async getImageData(element) {
    const canvas = await html2canvas(element);
    return {url: canvas.toDataURL("image/jpeg"), height: canvas.height, width: canvas.width}
  }

  async generateElement2pdf(element, doc: jsPDF) {
    const canvas = await this.getImageData(element)
    const image = canvas.url;
    const imgWidth = 210;
    const pageHeight = 295;
    let position = 0;
    let imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;
    doc.addPage();
    doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight - 5);
      heightLeft -= pageHeight;
    }
  }

}
