import { formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonDatetime, MenuController, ModalController, Platform } from '@ionic/angular';
// import { CalendarMode } from 'ionic2-calendar/calendar';
import * as moment from 'moment';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import { ModalDatetimeComponent } from '../modal-datetime/modal-datetime.component';
import {CalendarMode} from 'ionic2-calendar/calendar';

@Component({
  selector: 'app-modal-calendar-event',
  templateUrl: './modal-calendar-event.component.html',
  styleUrls: ['./modal-calendar-event.component.scss'],
})
export class ModalCalendarEventComponent implements OnInit {
  
  calendar = {
    mode: 'month' as CalendarMode,
    currentDate: new Date(),
    locale: 'fr',
    formatHourColumn: 'HH:mm',
  }
  
  viewTitle: string;
  
  event = {
    title: 'M. ou Mme.',
    desc: 'Type de garde ...',
    day: '',
    startTime: '',
    endTime: '',
  };
  markDisabled = (date: Date) => {
    var currentMonth = new Date().getMonth();
    return date.getMonth() < currentMonth || date.getMonth() > currentMonth;
  };
 
  modalReady = false;

  sliderOptions = {
    threshold: 50
  }

  @Input('selectedDay') selectedDay: any;

  selectedTimeStart: any;
  selectedTimeEnd: any;
  
  @Input('eventSource') eventSource;

  dateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ";

  @ViewChild('dateStart') dateStart: IonDatetime;
  @ViewChild('dateEnd') dateEnd: IonDatetime;

  constructor(
    private platform: Platform,
    private menu: MenuController,
    private keycloakIonicService: KeycloakIonicService,
    private alertCtrl: AlertController,
    @Inject(LOCALE_ID) private locale: string,
    private modalCtrl: ModalController,
    private alertSerivce: AlertService
    ) { }

  ngOnInit(): void {
    let currentDay = moment().date();
    this.event.day = currentDay.toString();
    this.event.startTime = moment().hours(0).minutes(0).seconds(0).milliseconds(0).format(this.dateFormat);
    this.event.endTime = moment(this.event.startTime).add(1, 'hours').format(this.dateFormat);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // this.calendar.currentDate = this.selectedDay;
      this.modalReady = true;
    }, 100)
  }

  async onEventSelected(event) {
    console.log(event)
    // Use Angular date pipe for conversion
    let start = formatDate(event.startTime, 'medium', this.locale);
    let end = formatDate(event.endTime, 'medium', this.locale);

    const alert = await this.alertCtrl.create({
      header: event.title,
      subHeader: event.desc,
      message: 'Du: ' + start + '<br><br>Au: ' + end,
      buttons: ['OK'],
    });
    alert.present();
  }

  onDateEndChange(ev: any): void {
    this.event.endTime = ev;
  }

  onDateStartChange(ev: any): void {
    this.event.startTime = ev;
  }


  save() {    
    this.modalCtrl.dismiss({event: this.event})
  }
 
  onViewTitleChanged(title) {
    this.viewTitle = title;
  }
 
  onTimeSelected(ev) {  
    let date = moment(ev.selectedTime).date();
    this.event.day = date.toString();
    this.event.startTime = moment(this.event.startTime).set('date', parseInt(this.event.day)).format(this.dateFormat);
    this.event.endTime = moment(this.event.endTime).set('date', parseInt(this.event.day)).format(this.dateFormat);
  }
 
  close() {
    this.modalCtrl.dismiss();
  }

  async openModalTime() {
    const modal = await this.modalCtrl.create({
      component: ModalDatetimeComponent,
      cssClass: 'modal-time',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then(data => {
      console.log(data);
    })
    await modal.present();
  }
}

