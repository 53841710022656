import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {RfeService, RequestForEstimate, PageQuery, ProposalService} from '@tatynanny/api';
import {merge} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import {AlertService} from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss'],
})
export class EstimateComponent implements OnInit {

  data: RequestForEstimate[] = [];
  displayedColumns: string[] = ["lastUpdateDate", "pricing.productType", "totalHoursPerMonth", "totalPriceTTC", "proposalGenerated", "action"];
  service: string = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageQuery: PageQuery = {
    direction: 'asc',
    sort: 'proposalGenerated',
    fields: [
      // {field: "proposalGenerated", value: JSON.stringify(false)},
      {field: "pricing.productType", value: ''},
    ],
  }

  constructor(
    private rfeService: RfeService,
    private router: Router,
    private proposalService: ProposalService,
    private alert: AlertService
  ) { }

  onServiceChange(event: any): void {
    console.log(event);
    this.pageQuery.fields = [
      // {field: "proposalGenerated", value: JSON.stringify(false)},
      {field: "pricing.productType", value: event.value}
    ]
    this.refresh();
  }

  ngOnInit(): void {
  }

  refresh(): void {
    this.rfeService.search(this.pageQuery).subscribe((res: any) => {
      // console.log(res);
      this.data = res.content;
    })
  }

  ionViewDidEnter() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.pageQuery.sort = this.sort.active;
      this.pageQuery.direction = this.sort.direction;
      console.log(this.sort.active);
    });

    this.pageQuery.page = this.paginator.pageIndex;
    this.pageQuery.pageSize = this.paginator.pageSize;

    merge(this.sort.sortChange, this.paginator.page).pipe(startWith({}), switchMap(() => {
      return this.rfeService.search(this.pageQuery);
    })).subscribe((data: any) => {
      console.log(data);
      (this.data = data.content)
    });
  }

  openEstimate(estimate: RequestForEstimate): void {
    if(estimate.proposalGenerated) {
      this.proposalService.loadProposalByRfe(estimate.id).subscribe(res => {
        if(res.result) {
          this.router.navigateByUrl(`/mon-espace/devis/detail/${res.result.id}`);
        }
      }, (error) => {
        this.alert.error(`${error.error}`);
      });
    } else {
      this.router.navigateByUrl(`/mon-espace/demandes/${estimate.id}`);
    }
  }
}