import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CampaignEmailForm, CampaignEmailService} from '@tatynanny/api';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit {

  campaignEmailForm: CampaignEmailForm = {
    email: "",
  }

  error: string = "";

  constructor(
    private modalCtrl: ModalController,
    private campaignService: CampaignEmailService,
  ) { }

  ngOnInit() {}

  dismiss(): void {
    this.modalCtrl.dismiss(true);
  }

  dismissModal(): void {
    this.modalCtrl.dismiss(false);
  }

  submit(): void {
    this.campaignService.subscribe(this.campaignEmailForm).subscribe(res => {
      console.log(res);
      if(res.status) {
        this.error = "";
        this.dismiss();
      } else {
        this.error = "Mail déjà utilisé";
      }
    })
  }

}
