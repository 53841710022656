import { Injectable } from '@angular/core';
import {AlertService} from '../alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private alertService: AlertService,
  ) { }

  showError(fieldErrors: any) {
    if(fieldErrors) {
      let message = "";
      fieldErrors.forEach(error => {
        message = message + error.message + '<br>';
      });
      message = `<div style="text-align: justify; display: flex; justify-content: center; color: red">` + message + `</div>`;
      this.alertService.error(message);
    }
  }


}
