import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-occasionnel',
  templateUrl: './occasionnel.component.html',
  styleUrls: ['./occasionnel.component.scss'],
})
export class OccasionnelComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
