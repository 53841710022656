import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-histoire',
  templateUrl: './histoire.component.html',
  styleUrls: ['./histoire.component.scss'],
})
export class HistoireComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
