import {ViewportScroller} from '@angular/common';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonContent} from '@ionic/angular';
import {LangOptions, Pricing, RegisterAccountForm, RequestForEstimate, RfeService} from '@tatynanny/api';
import {AlertService} from 'src/app/services/alert/alert.service';
import {ErrorService} from 'src/app/services/error/error.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {KeycloakSocialLoginService} from 'src/app/services/keycloak/keycloak-social-login.service';

@Component({
  selector: 'app-ask-proposal',
  templateUrl: './ask-proposal.component.html',
  styleUrls: ['./ask-proposal.component.scss'],
})
export class AskProposalComponent implements OnInit {

  @Input('isComponent') isComponent: boolean = false;

  segment: 'SERVICE' | 'NEEDS' | 'INFOS' = 'SERVICE';
  segmentService: 'EXTRACURRICULAR' | 'EDUCATIONAL_SUPPORT' | 'MAID' | "" = "";
  minAge: "LESS_1" | 'BETWEEN_1_TO_2' | 'BETWEEN_3_TO_6' | 'MORE_6' | "" = ""
  nbChild: 1 | 2 | 3 | 0 = 0;
  school: 'PRIMARY' | 'COLLEGE' | 'HIGH SCHOOL' | '' = '';
  progress: number = 0.33;

  rfe: RequestForEstimate = {
    pricing: {} as Pricing,
    timeList: [],
    availableDuringHolidays: false,
    startDate: "",
    increaseLang: false,
    increaseChildren: false,
    langOptions: LangOptions.Anglais,
  } as RequestForEstimate;

  @ViewChild('content') content: IonContent;

  constructor(
    private rfeService: RfeService,
    private alertService: AlertService,
    private kcService: KeycloakIonicService,
    private errorService: ErrorService,
    private keycloakSocialLoginService: KeycloakSocialLoginService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit() {
    if (localStorage.getItem('selectedDays')) {
      localStorage.removeItem('selectedDays');
    }
    console.log('[ask-proposal] (ngOnInit) rfe', this.rfe);
  }

  addProgress(value: number): void {
    this.progress = this.progress + value;
  }

  selectService(service: any): void {
    console.log(service);
    if (this.segmentService == service) {
      this.segmentService = "";
    } else {
      this.segmentService = service;
    }
  }
  selectChildren(number: any): void {
    if (this.nbChild == number) {
      this.nbChild = 0;
    } else {
      this.nbChild = number;
    }
  }
  selectMinAge(age: any): void {
    if (this.minAge == age) {
      this.minAge = "";
    } else {
      this.minAge = age;
    }
  }
  selectSchool(school: any): void {
    if (this.school == school) {
      this.school = "";
    } else {
      this.school = school;
    }
  }

  next(rfe?: RequestForEstimate): void {
    this.scrollToTop();
    this.rfe.pricing = rfe.pricing;
    if (this.segment == 'SERVICE') {
      this.segment = 'NEEDS';
    } else if (this.segment == 'NEEDS') {
      this.segment = 'INFOS';
    }
  }

  validateRfe(rfe: any): void {
    this.scrollToTop();
    console.log('[ask-proposal] (validateRfe) rfe', rfe);
    this.segment = 'INFOS';
  }

  back(rfe?: RequestForEstimate): void {
    this.scrollToTop();
    console.log('[ask-proposal] (back) rfe', rfe);
    this.rfe = rfe;
    if (this.segment == 'INFOS') {
      this.segment = 'NEEDS';
    } else if (this.segment == 'NEEDS') {
      this.segment = 'SERVICE';
    }
  }

  changeSegment(segment: string): void {
    this.scrollToTop();
    if (segment == 'SERVICE') {
      this.segment = 'SERVICE';
    } else if (segment == 'NEEDS') {
      if (this.segment == 'INFOS') {
        this.segment = 'NEEDS';
      }
    }
  }


  scrollToTop() {
    this.content.scrollToTop(0);
  }



  register(form: RegisterAccountForm): void {
    console.log('[ask-proposal] (register) form', form);
    this.alertService.loading();
    if(form.nbChild >= 3) {
      form.increaseChildren = true;
    }
    this.rfeService.registerRfe(form).subscribe(res => {
      console.log('[ask-proposal-info] (finalize)', res);
      if (res.status) {
        this.alertService.closeLoader();
        this.alertService.confirm(`Connectez vous à votre espace pour accéder à votre devis. 
          Nous vous contactons dans les plus brefs délais`, 'Se connecter', () => {
          this.kcService.login({
            loginHint: form.email,
          });
        });
      } else {
        this.alertService.closeLoader();
        this.errorService.showError(res.errors.fieldErrors);
      }
    }, (error) => {
      this.alertService.closeLoader();
      this.alertService.error(`${error.error}`);
    });
  }
}
